import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CalculatorModal from "./components/CalculatorModal";
import "./index.css";
import { Modal, Ripple, initTE } from "tw-elements";
import UnsuspiciousActivityModal from "./components/UnsuspiciousActivityModal";
import { AssessementContext } from "../../AssessmentContext";
import QuestionTypeWrapper from "./components/question-types/QuestionTypeWrapper";
import { toast } from "react-hot-toast";
import TimeOutModal from "./components/TimeOutModal";
import CompletedAssessmentModal from "./components/CompletedAssessmentModal";
import AssessmentTerminationModal from "./components/AssessmentTerminationModal";
import { useMutation } from "react-query";
import { api } from '../../../../../api';
import ScaleLoader  from  "../../components/ScaleLoader";
import SubmitConfirmModal from "./components/SubmitConfirmModal";
import PreviewExamModal from "./components/PreviewExamModal";
import QuestionIndexGotoModal from "./components/QuestionIndexGotoModal";
import { useDispatch, useSelector } from "react-redux";
import { 
        addFailedSubmittedQuestion,
       addFlaggedQuestion, addQuestionDuration, addQuestionSavedAnswers, 
       clearExamQuestions, 
       clearFailedQuestions, 
       clearQuestionDuration, 
       clearRecentActiveExamData, 
       flushSubmittedAnswers, updateExamQuestions, updateQuestionVisitCount, updateTimeLeft 
      } from '../../../../../features/candidate-exam/candidate-exam-slice'
import ResizeWindowModal from "./components/ResizeWindowWarningModal";
import { updatedSavedAnswerPayload } from './components/utils/transformSavedAnswerFormat';
import NetworkErrorModal from "./components/NetworkErrorModal";
import { exitFullScreen, toPlainAnwers, tranformToUniqueData } from "./components/utils/functions";
import platform from "platform";

const CandidateAssessment = ({minutes, seconds, hours, totalSeconds, pause, restart }) => {

const [openModal, setOpenModal ] = useState(false)
const { assessment, setAssessment } = useContext(  AssessementContext )
const [showunusualModal,SetShowUnusualModal] = useState(false)
const [isSubmitting, setIsSubmitting] = useState(false)
const [showResizeModal,setShowResizeModal] = useState(false)
const [networkState, setNetworkState] = useState('normal')
const container = document.querySelector(`#parent-container`)
const location = useLocation()
const navigate = useNavigate()
const [timeSpend, setTimeSpend ] = useState(0)
const dispatch = useDispatch()
const questions = assessment?.assessements?.questions;
const { questionSavedAnswers, questionsDuration, examQuestions } = useSelector( state => state.candidateExam)

const isMobileDevice = () => {
   return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.innerWidth < 550;
 } 
 
const finishExamMutation = useMutation(api.gradeAssessment,{
   onSuccess : () => {
      toast.remove();
      setAssessment({...assessment, isCompleted : true, savedAnswers: [], questionNumber: 0 })
       pause()
       dispatch( clearRecentActiveExamData() )
       dispatch( clearQuestionDuration() )
       dispatch( clearExamQuestions() )
       sessionStorage.setItem('exam-submit-status', 0)
       setIsSubmitting(false)
       if(sessionStorage.getItem('exam-type') === 'mock'){
          navigate('/candidate/exam/result')
       }
  },
  onError : () => {
     toast.remove();
     setIsSubmitting(false)
     toast.error('Error! an unknown error occured.')
  },
  onMutate : () => {
   toast.loading("submitting exam, please wait...");
}
})

 const flagExamQuestionMutation = useMutation(api.flAgQuestion,{
   onSuccess : () => {
      toast.remove();
      toast.success('Question is sucessfully flagged.')
  },
  onError : () => {
     toast.remove();
     toast.error('Error! an unknown error occured.')
  },
  onMutate : () => {
   toast.loading("flaging question...");
  }
  })

  const sentExamErrorLogMutation = useMutation(api.sentExamErrorLog,{
   onSuccess : (data) => {
       setNetworkState('poor')
       setTimeout( () => {
            exitFullScreen()
            navigate('/')
      }, 60000 )
  },
  })
  
  const getQuestionsmutation = useMutation(api.getExamQuestions, {
   onSuccess: (data) => {
     //compare between current question and prevoius saved questions
     const questions = data?.data?.questions?.filter( q => {
        return q?.answers?.some( ans => ans?.status === true )
     })
     if(examQuestions?.length){
        if(questions?.length === 0 ){
           //sync client answers
           const client_answers = toPlainAnwers(examQuestions)
           if(client_answers.length) updateAnswers(client_answers,data?.data?.questions)
        }
        else{ 
           //check for unsync questions and retry submitting
           const client_answers = toPlainAnwers(examQuestions)
           const server_answers = toPlainAnwers(questions)
           const unsynced_answers = client_answers.filter((client_answer) => {
             return !server_answers.some((server_answer) => {
                 return ((client_answer.question_id === server_answer.question_id)
                          && (client_answer.answer_id === server_answer.answer_id)
                         );
             } )
           });
          //update answers
          if(unsynced_answers?.length) updateAnswers(unsynced_answers,data?.data?.questions)
          //finish assessment
          else {
               //finish exam
               finishExamMutation.mutate({
                  ...JSON.parse(window.sessionStorage.getItem('submit-assessment-payload')),
                  status: 'finished'
               })
          }
        }   
     }
   }
 });

const updateAnswersMutation = useMutation(api.updateAnswers,{
   onSuccess : (response) => {
       //restart the timer in case if there is any time added
       //restart(new Date().getTime() + response?.data?.time_left * 60 * 1000 )
       toast.remove();
       dispatch( flushSubmittedAnswers() )  
       if(parseInt(sessionStorage.getItem('is-network-failure'))){
         sessionStorage.setItem('is-network-failure', '0')
         dispatch( clearFailedQuestions() )
       }      
       if(parseInt(sessionStorage.getItem('exam-submit-status'))){
           //finish exam
           finishExamMutation.mutate({
            ...JSON.parse(window.sessionStorage.getItem('submit-assessment-payload')),
            status: 'finished'
           })
       }        
   },
   onError: (error) => {

      const deviceInfo = platform.parse(navigator.userAgent)
      let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      const assessmentId = JSON.parse(sessionStorage.getItem('active-assessment')).assessment_id
     
      const getDeviceType = () => {
         const userAgent = navigator.userAgent;
         if (/mobile/i.test(userAgent)) {
             return "Mobile";
         } else if (/tablet/i.test(userAgent)) {
             return "Tablet";
         } else {
             return "Desktop";
         }
     }
      const payload = {
            appName: "Testassessify",
            deviceInfo: `${deviceInfo.description} device type = ${
               getDeviceType()
            }${connection ? `Network Effective connection type: ${
               connection.effectiveType
            } Downlink speed: ${connection.downlink} Mb/s`: ''}`,
            browserInfo: `${deviceInfo.name} Version = ${deviceInfo.version}`,
            platformInfo: deviceInfo.ua,
            causeOfError: error?.response?.message,
            url: window.location.href,
            payload: {
               user_group_id: sessionStorage.getItem("user_group_id"),
               assessment_id: assessmentId,
               data: {
                  ...updatedSavedAnswerPayload(
                     [...questionSavedAnswers.map( answer => {
                     return {
                        duration : answer?.duration,
                        answer_id : answer?.answer_id ,
                        question_id: answer?.question_id,
                        answer_text: answer?.answer_text,    
                     }
                     })], 
                     questionsDuration,  
                     questions,
                  ),
                 time_left: totalSeconds,
               }
          },
            response: error?.response?.message ,
            responseCode: error?.response?.status
      }

      if(!window.navigator.onLine){
         sentExamErrorLogMutation.mutate({
            ...payload, response: 'Request failed',
            responseCode: null, 
            causeOfError: "internet disconnected (no connection)"
          })
          setNetworkState('poor')
         toast.error('Error! you are not connected to the internet')
         setTimeout( () => {
              exitFullScreen()
              navigate('/')
        }, 60000 )
      }
      else if(error.message.includes('502')){
        sentExamErrorLogMutation.mutate({
          ...payload, response: 'Internal server error',
          responseCode: '502', causeOfError: 'Server error 502'
        })
      }
      else sentExamErrorLogMutation.mutate(payload)
   }
})

const displayUnsualActivityModal = () => {        
   //document.querySelector('button.unusual-activity-button').click()   
   const actualDeviceWidth = parseInt(sessionStorage.getItem('device-width')) 
   const actualDeviceHeight = parseInt(sessionStorage.getItem('device-height'))  

    if ((window.innerWidth !== actualDeviceWidth)) {
      if(!isMobileDevice()){
      SetShowUnusualModal(true)
      toast.error('you resize the browser window')
      }
    }
    else if(actualDeviceHeight === window.innerHeight){
         setShowResizeModal(true)
      }
}
/* reusable function for updating answers */
const updateAnswers = (answers) => {
   const assessmentId = JSON.parse(sessionStorage.getItem('active-assessment')).assessment_id
   updateAnswersMutation.mutate({
     user_group_id: sessionStorage.getItem("user_group_id"),
     assessment_id: assessmentId,
     data: {
        ...updatedSavedAnswerPayload(
           [...answers.map( answer => {
           return {
              duration : answer?.duration,
              answer_id : answer?.answer_id ,
              question_id: answer?.question_id,
              answer_text: answer?.answer_text,    
           }
           })], 
           questionsDuration,  
           questions,
        ),
       time_left: totalSeconds,
     }
})
}

useEffect( () => {
   // clear recently submitted answers
      setTimeout( () => {
         dispatch( flushSubmittedAnswers() )
      } , 500 )
}, [] )

useEffect( () => {
   
   const single_assessment = questions[assessment.questionNumber]
   dispatch(addQuestionDuration(
      {
         id: single_assessment?.id,
         questionNumber: assessment?.questionNumber + 1,
         duration: timeSpend
      }
    ))
    dispatch( updateExamQuestions( questions ) )
    sessionStorage.setItem('client_side_time_left', totalSeconds)
}, [timeSpend] )

useEffect( () => {
   const single_assessment = questions[assessment.questionNumber]
   dispatch( updateQuestionVisitCount(single_assessment?.id) ) 
   setTimeSpend(0)
   /* automatically submit answers once 5 question are answered incase of random selection  */
   if(questionSavedAnswers?.length){
      if(tranformToUniqueData(questionSavedAnswers)?.length >= 5 ){
         updateAnswers(questionSavedAnswers)
      }
   }
}, [assessment.questionNumber] )

useEffect(() => {      
      const single_assessment = questions[assessment.questionNumber]
      //if(assessment.questionNumber === questions?.length - 1){
         const updated_saved_answers = single_assessment?.answers?.filter( ans => ans?.status === true )                    

          dispatch(addQuestionSavedAnswers([updated_saved_answers.map( answer => {
              return { 
                  answer_id: answer?.id,
                  question_id: single_assessment?.id,
                  answer_text: answer?.text,
                  question_type: single_assessment.type 
            }
          })]))  
          // check and submit assessment on time out 
          if( hours === 0 && minutes === 0 && seconds === 0 ){
            setAssessment({ ...assessment, isTimedOut: true });
          }    
    //  }
   }, [timeSpend])

  useEffect(() => {
      // Start the timer when the component mounts
      const intervalId = setInterval(() => {
      setTimeSpend((prevSeconds) => prevSeconds + 1);
      }, 1000)
      // Clear the interval when the component unmounts to avoid memory leaks
      return () => clearInterval(intervalId);
  }, [assessment.questionNumber, ]);

  useEffect( () => {

   const contactButton = document.querySelector('.woot-widget-bubble.woot-elements--right')
    
   //-------- hide and contact us button when exam is taken ----------------------//
   if(contactButton){
      if(location.pathname.includes('candidate-assessment')){
       contactButton.style.visibility = 'hidden'       
       }
      else {
          contactButton.style.visibility = 'visible'   
         }  
     }
  },[location.pathname])

  useEffect(() => {
   container.scrollTo( { top : 0, } )
   sessionStorage.setItem('last_asessement_id',assessment?.assessements?.assessment_id);
   sessionStorage.setItem('last_asessement_user_group_id',sessionStorage.getItem("user_group_id"));
   const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // User has switched to another tab or minimized the browser window        
        toast.error('you had open a new program')
        SetShowUnusualModal(true)
      } else {
        // User has returned to the tab
        toast.error('you are back')
        SetShowUnusualModal(true)        
      }
    };

     const handlePrint = (event) => {
      event.preventDefault(); // prevent the print dialog from opening
      toast.error('printing screen is not allowed!')
      SetShowUnusualModal(true)
    }
    
    const navigation = () => {
      window.location.assign( window.location.href.replace(
         'candidate-assessment', sessionStorage.getItem('active-page-view') !== 'mock-page' ?
          'view-assessments' : 'view-practice-exams'
       ) ) 
    }

    window.onbeforeprint = handlePrint;
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('load', navigation)

    return () => {
      window.onbeforeprint = null;
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
 }, [])


 useEffect( () => {
    initTE({ Modal, Ripple });
    window.addEventListener('resize',displayUnsualActivityModal)
    // continue exam from last question viewed
     const last_question_viewed = parseInt(sessionStorage.getItem('last_question_viewed')) > 0 ?
                                    parseInt(sessionStorage.getItem('last_question_viewed')) : 0
    if(last_question_viewed && (last_question_viewed <= questions?.length)){       
       setAssessment({
         ...assessment, inEditMode: true,
         activeQuestion : assessment.assessements.questions[
            last_question_viewed
         ],
         questionNumber : last_question_viewed,
         questionType : assessment.assessements.questions[
          last_question_viewed
       ]['type'],      
      })         
    }

    return () => {
      window.removeEventListener('resize', displayUnsualActivityModal);
    }
},[])

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);  
//-------------------------------------------------------------------------------------------------------//
        // move to next question
//--------------------------------------------------------------------------------------------------------//
 
const moveToNextQuestion = () => {
     sessionStorage.setItem('exam-submit-status', 0)
     
      if(assessment.questionNumber < questions?.length - 1 ){
         setAssessment({
            ...assessment, inEditMode:false,
            activeQuestion : assessment.assessements.questions[
               assessment.questionNumber + 1
            ],
            questionNumber : assessment.questionNumber + 1,
            questionType : assessment.assessements.questions[
               assessment.questionNumber + 1
            ]['type'],   
           // savedAnswers:        
         })              
      }
      
      //const answers = assessment.savedAnswers.filter( answer => answer.question_id === single_assessment.id)
      const assessmentId = JSON.parse(sessionStorage.getItem('active-assessment')).assessment_id
      //submit exam answer in batches after completing every 5 question
      const active_question_number = assessment?.questionNumber + 1
       
     if(((active_question_number) % 5 ) === 0){
       if(active_question_number === (questions?.length)){
         // toast.success('submiting...')
       }
       else{
         //submit 5 question answers (update answers endpoint)
         if(questionSavedAnswers.length){
            updateAnswers(questionSavedAnswers)
         }             
       }
     }
     container.scrollTo( { top : 0, } )  
     setTimeSpend(0)
}
//-------------------------------------------------------------------------------------------------------//
        // move to previous question
//--------------------------------------------------------------------------------------------------------//
const moveToPrevQuestion = () => {
   sessionStorage.setItem('exam-submit-status', 0)
   const active_question_number = assessment?.questionNumber + 1
   if(((active_question_number) % 5 ) === 0){
      dispatch( flushSubmittedAnswers() )
   }
  if(assessment.questionNumber > 0 ){
    setAssessment({
       ...assessment, inEditMode: true,
       activeQuestion : assessment.assessements.questions[
          assessment.questionNumber - 1
       ],
       questionNumber : assessment.questionNumber - 1,
       questionType : assessment.assessements.questions[
        assessment.questionNumber - 1
     ]['type'],      
    })   
           
 }
 container.scrollTo( { top : 0, } )
 setTimeSpend(0)
}

const gotoQuestion = (index) => {
   setAssessment({
      ...assessment, inEditMode: true,
      activeQuestion : assessment.assessements.questions[
         index
      ],
      questionNumber : index,
      questionType : assessment.assessements.questions[
       index
    ]['type'],      
   })            
   container.scrollTo( { top : 0, } )
   setTimeSpend(0)
}

  const flagIncorrectQuestion = (id) => {
       flagExamQuestionMutation.mutate(id) 
       dispatch(addFlaggedQuestion(id))
    }

const submitAssessment = (single_assessment) => {
   setIsSubmitting(true)
   const active_question_number = assessment?.questionNumber + 1
   const assessmentId = JSON.parse(sessionStorage.getItem('active-assessment')).assessment_id
   if(active_question_number === (questions?.length)){
      //submit the rest of the question if is less than 5
      sessionStorage.setItem('exam-submit-status', 1)
      getQuestionsmutation.mutate({
         assessmentId: sessionStorage.getItem("active-assessment-id"),
         groupId: sessionStorage.getItem("user_group_id"),
       });
   }    
}

  return (
    <div className="flex"> { !finishExamMutation.isLoading ?  
       <>  {networkState === 'poor' ? <NetworkErrorModal /> : null}
         <div className="w-[33px] md:w-[48px]"></div>
         <section style={{ width: 'calc(100vw - 50px)'}} className='md:ml-5 candidate-assessment jumbotron bg-white px-2 md:px-4 p-4'>                
                <span className='mx-2'>/</span>
                <Link to={'/candidate/assessments/candidate-assessment'} className='text-md text-customGreen'>
                    Candidate Assessment
                </Link>    
                <div className='mt-8 py-12 bg-darkBlue px-5 text-white rounded-lg'>
                     <h5 className='text-2xl flex justify-between'>
                        {assessment?.activeAssessment?.assessment_title}  
                         <span className='text-xl flex items-center'> 
                            <i className='text-2xl bi bi-clock-history mr-2'>
                             </i>   {hours < 10 ? `0${hours}` : hours }: {minutes < 10 ? `0${minutes}` : minutes }: {seconds < 10 ? `0${seconds}` : seconds }
                         </span>
                     </h5> 
                     <p className='text-[12px]'>
                        please attempt all questions.
                     </p>
                     <div className='mt-4 bg-white py-4 rounded-lg text-gray-900 px-4 flex justify-between'>
                          <span> 
                              Question {assessment.questionNumber+1} of {questions?.length}
                           </span>
                          <span> Section: &nbsp;{assessment.activeQuestion?.section?.title}</span>
                     </div>
                </div> 
                 {
                  assessment?.activeQuestion?.direction?.title && 
                  <div className='mt-8 mb-5 border bg-gray-100 border-gray-300 text-lg py-0 px-0 rounded-lg'>                     
                     <p className="p-4 text-lg italic" 
                         dangerouslySetInnerHTML={{
                             __html : assessment?.activeQuestion?.direction?.content
                          }}
                     />
                  </div>
                 }
                <div className='mt-12'>
                  {
                    questions?.map( (single_assessment,index) => {
                        if(assessment.questionNumber === index){
                           return <React.Fragment key={index} >
                              <h5 className='text-xl mb-3'>
                                  Question  (                                                  
                                      <span className=' text-xl px-1'>
                                        { assessment.questionNumber + 1 }
                                         </span>        
                                     <span className='text-green-500 text-xl pl-1'>of </span>
                                    &nbsp;{questions?.length} )
                              </h5>
                              <p 
                                   className='text-[17px]' 
                                   dangerouslySetInnerHTML={{ __html : (single_assessment['text']) }} 
                             />
                              <h5 className='text-xl mt-12 mb-3'> Options </h5>                      
                                  <QuestionTypeWrapper 
                                        setShowUnusualModal = {SetShowUnusualModal}
                                        single_assessment={single_assessment} 
                                  /> 
                              <div className='mt-[130px] flex justify-between md:justify-end items-center'>
                             {/* <button
                                 type="button"
                                 className="bi bi-chevron-bar-contract px-1 md:px-5 text-3xl"
                                 data-te-toggle="modal"                                  
                                 data-te-target={`#leftBottomModal-${single_assessment.id}`}
                                 data-te-ripple-init
                                 data-te-ripple-color="light">                                  
                              </button>*/}
                                <QuestionIndexGotoModal
                                   id={`leftBottomModal-${single_assessment.id}`}
                                   questions = { questions }
                                   activeQuestion = { assessment.questionNumber }
                                   gotoQuestion = { gotoQuestion }
                                  />
                                 <button
                                    onClick={ () => flagIncorrectQuestion(single_assessment.id) }
                                    className='bg-gray-200 bi bi-flag py-3 px-3 mr-3 md:mr-12 rounded-lg  text-lg'>
                                       &nbsp;Flag Question
                                 </button>
                                 <div className='flex ml-[5px] sm:ml-0 justify-between items-center '>                                   
                                 <button 
                                    className='bi bi-calculator-fill text-green-900 text-4xl mr-4 md:mr-16'
                                    onClick={ handleOpenModal }
                                    ></button>
                                    {openModal && <CalculatorModal closeModal = { handleCloseModal } />}
                                 <button
                                    onClick={ () => moveToPrevQuestion(single_assessment) }
                                 className='py-3 px-8 md:px-16 rounded-lg text-darkBlue  hover:text-white active:text-white hover:bg-darkBlue active:bg-darkBlue    border border-darkBlue text-lg'> 
                                 <i className='bi bi-chevron-double-left mr-3'></i> Prev
                                 </button>
                                    {
                                     questions.length - 1 !== index ?
                                    <button                             
                                    onClick={ () => moveToNextQuestion(single_assessment)  }
                                    className='py-3 next-btn px-8 md:px-16 rounded-lg ml-8 md:ml-16 hover:bg-darkBlue hover:text-white active:text-white active:bg-darkBlue text-darkBlue border border-darkBlue text-lg'
                                    id="next-button"> 
                                       Next <i className='bi bi-chevron-double-right ml-3'></i>
                                    </button>
                                     :  
                                     <>
                                     {
                                      isSubmitting ?
                                      <button                                          
                                        className='py-3 px-4 md:px-16 rounded-lg ml-8 md:ml-16  border  bg-gray-200 text-lg'
                                       > 
                                         Processing...
                                      </button>
                                      :
                                     <button   
                                       id="submit-button"                                                                 
                                       data-te-toggle="modal"
                                       data-te-target={`#modal-${single_assessment.id}`}
                                       data-te-ripple-init
                                       data-te-ripple-color="light"
                                       className='py-3 px-4 md:px-16 rounded-lg ml-8 md:ml-16 text-white border hover:text-white active:text-white bg-darkBlue border-blue-500 text-lg'> 
                                        Submit <i className='bi bi-chevron-double-right ml-3'></i>
                                     </button>}
                                     <SubmitConfirmModal 
                                       id={`modal-${single_assessment.id}`}
                                       lastQuestion = {questions[ questions?.length - 1 ]}
                                       submitAssessment = { () => submitAssessment(single_assessment) }
                                     />                                      
                                     </>
                                    }
                                     {
                                       showunusualModal &&
                                       <UnsuspiciousActivityModal SetShowUnusualModal={SetShowUnusualModal}/>
                                     }
                                     {
                                       showResizeModal &&
                                       <ResizeWindowModal setShowResizeModal = {setShowResizeModal} />
                                     }
                                 </div>
                              </div>
                       </React.Fragment>
                        }
                    } )
                  }
                </div>
                {assessment?.isTimedOut && <TimeOutModal /> }
                { assessment?.isCompleted && <CompletedAssessmentModal /> }
                { assessment?.isTerminated && <AssessmentTerminationModal /> }
                <PreviewExamModal
                  minutes = {minutes}
                  hours = { hours }
                  seconds = { seconds }
                  assessment = { assessment }
                  submitAssessment = { submitAssessment }
                />
        </section>
       </>
       : <ScaleLoader /> } 
    </div>
  );
};

export default CandidateAssessment;
