import React from 'react'
import { Link } from 'react-router-dom'

const JobCard = ({ title,company, type, location, date, link, status, closed }) => {

  const timeAgo = (date) => {
    const currentDate = new Date();
    const timestamp = date.getTime();
    const currentTimestamp = currentDate.getTime();
    const timeDifference = currentTimestamp - timestamp;
  
    // Define time units in milliseconds
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const week = 7 * day;
    const month = 30 * day;
    const year = 365 * day;
  
    if (timeDifference < minute) {
      return 'Just now';
    } else if (timeDifference < hour) {
      return Math.floor(timeDifference / minute) + 'm ago';
    } else if (timeDifference < day) {
      return Math.floor(timeDifference / hour) + 'h ago';
    } else if (timeDifference < week) {
      return Math.floor(timeDifference / day) + 'd ago';
    } else if (timeDifference < month) {
      return Math.floor(timeDifference / week) + 'w ago';
    } else if (timeDifference < year) {
      return Math.floor(timeDifference / month) + 'mo ago';
    } else {
      return Math.floor(timeDifference / year) + 'y ago';
    }
  }

  return (
    <div className='rounded-lg bg-neutral-50'>
         {
            closed ?
            <span className='block pl-1 text-white text-xs pt-1 job-batch-card bg-customGreen h-[30px] w-[120px]'>
               Open
           </span>
           :
           <span className='block pl-1 text-white text-xs pt-1 job-batch-card bg-red-500 h-[30px] w-[120px]'>
             Close
           </span>
         }
        <div className='p-3'>
           <h5 className='text-xl mt-2 capitalize'>{title}</h5>
          <p className='mt-2 mb-3 text-sm capitalize'>{company}</p>
          <div className='flex justify-end'>
              <button className='py-2 text-xs bi bi-alarm bg-white px-3'>
                 &nbsp;{timeAgo(date)}
              </button>
          </div>
          <div className='mt-4 flex justify-end'>
               <a href={link} className='text-md text-customGreen' target='_blank'>
                  Apply <span className='ml-1 bi bi-chevron-double-right'></span>
               </a>
          </div>
        </div>
    </div>
  )
}

export default JobCard