import React from 'react'
import { AssessementContext } from '../../../../AssessmentContext';
import { toggleCheckedAnswer } from '../../../../../../../features/candidate-exam/candidate-exam-slice';
import { useDispatch } from 'react-redux';


const RadioSelectQuestions = ({question}) => {

  const { assessment, setAssessment } = React.useContext( AssessementContext )
  const options = ['A','B','C','D','E']
  const dispatch = useDispatch()

  const handleSavedAnswer = (event,answer, id) => {
  
    if(question?.type.toLowerCase() !== 'select'){
    //remove unchecked answer for checkbox inputs
    if(!document.querySelector(`#${id}`).checked){
      dispatch( toggleCheckedAnswer(answer?.id))
      setAssessment(prevAssessment => {
        return { 
                  ...prevAssessment, 
                  assessements: {...prevAssessment?.assessements,
                    questions: [
                       ...prevAssessment?.assessements?.questions?.map( question => {
                          return {
                             ...question, answers : [...question?.answers?.map( ans => {
                               if(answer?.id === ans?.id ){
                                  return {...ans, status: false }
                               }
                               else return {...ans}
                             })]
                          }
                       })
                    ]
                  }
            }
       })   
    }
    else{
      setAssessment(prevAssessment => {
        return { 
                  ...prevAssessment, 
                  assessements: {...prevAssessment?.assessements,
                    questions: [
                       ...prevAssessment?.assessements?.questions?.map( question => {
                          return {
                             ...question, answers : [...question?.answers?.map( ans => {
                               if(answer?.id === ans?.id ){
                                  return {...ans, status: true }
                               }
                               else return {...ans}
                             })]
                          }
                       })
                    ]
                  }
            }
       })  
    }
  }
    else{
      setAssessment(prevAssessment => {
        return { 
                  ...prevAssessment, 
                  assessements: {...prevAssessment?.assessements,
                    questions: [
                       ...prevAssessment?.assessements?.questions?.map( question_ => {
                         if(question?.id === question_?.id){
                          return {
                             ...question_, answers : [...question_?.answers?.map( ans => {
                               if(answer?.id === ans?.id ){
                                  return {...ans, status: true }
                               }
                               else return {...ans, status: false}
                             })]
                          }}
                          else return {...question_}
                       })
                    ]
                  }
            }
       })   
    }   
}

  return (
    <div> 
      <form className={`form-${question.id}`}>
        <div className='flex flex-wrap md:px-8'>  
              {
                 assessment.questionType.toLowerCase() === 'select' ?                    
                       assessment.activeQuestion?.answers.slice(0,5).map( (answer, id) => {
                           return(                             
                            <div key={id} className='flex items-center  w-[90%] md:w-[35%] pr-5 ml-5 mb-12'>               
                                 <div className='w-[20px] h-[20px] mr-2'>
                                 <input 
                                     type="radio" name="select"
                                     className='w-[20px] h-[20px] mr-2 block'
                                     style = { { width: '20px !important', height: '20px !important' }}
                                      value={answer?.text}
                                      id= {options[id]}
                                      defaultChecked = { 
                                         ( answer?.status ) ? true : false                                         
                                       }
                                      onClick={ (event) => handleSavedAnswer(event,answer) }
                                  />                                   
                                 </div>
                                 <span className='text-xl flex items-center'>
                                 <label htmlFor={options[id]} className='text-lg mr-2 px-4 py-2 rounded-full bg-gray-700 text-white'>
                                     {options[id]}
                                 </label> &nbsp;   
                                     <span className='answer-wrapper text-xl' dangerouslySetInnerHTML={{__html: answer.text}} />
                                 </span>
                             </div>                                                          
                           )
                       } )                    
                    :
                    assessment.activeQuestion?.answers.slice(0,5).map( (answer, id) => {
                      return(                             
                       <div key={id} className='flex items-center w-[90%] md:w-[35%] ml-8 pr-5 mb-12'>               
                             <div>
                                <input 
                                    type="checkbox" name={id} 
                                    className='w-[20px] h-[20px] mr-2 block'
                                    style = { { width: '20px !important', height: '20px !important' }}
                                    value={answer?.text}
                                    id= {options[id]}
                                    defaultChecked = { 
                                        ( answer?.status ) ? true : false                                         
                                      }
                                    onChange={ (event) => handleSavedAnswer(event,answer, options[id] ) }
                                />
                             </div>
                            <span className='text-xl flex items-center'> 
                               <label htmlFor={options[id]} className='text-lg mr-2 px-4 py-2 rounded-full bg-gray-700 text-white'>
                                 {options[id]}
                              </label> &nbsp; 
                                <span className='answer-wrapper text-xl' dangerouslySetInnerHTML={{__html: answer.text}} />
                                </span>
                        </div>                                                          
                      )
                  } )                     
              }
        </div>        
      </form>
    </div>
  )
}

export default RadioSelectQuestions