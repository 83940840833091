import React from 'react'

const ResizeWindowModal = ({setShowResizeModal}) => {
 
 const reOpenFullScreen = () => {
    setShowResizeModal(false)
    setTimeout(() => {
    const element = document.documentElement; // Get the root element of the document
    if (element.requestFullscreen) {
       element.requestFullscreen();
       sessionStorage.setItem('full_screen_mode', 1)
    }  
    },0);
 }

  return (
    <div className='h-[100vh] w-[100vw] bg-[rgba(0,0,0,0.3)] fixed top-0 left-0' style={{ zIndex:9000000}}>
        <div className='w-full h-full flex items-center justify-center'>
             <div className='modal-conetent bg-white w-[94%] md:w-[500px] h-[420px] md:h-[400px] rounded-lg'>
                  <h5 className='py-5 px-3 mb-5 shadow-lg text-xl'> Warning !!! </h5>
                 <div className='w-[94%] md:w-[90%] mx-auto h-[210px] mt-1 calc'>
                     <p className='flex justify-center'>
                        <span className='text-[80px] md:text-[100px] bi bi-exclamation-triangle text-yellow-500'>
                        </span>
                     </p>
                     
                     <p className='text-xl text-center my-1'>
                        You Exit Full Screen Mode.
                     </p>
                     <p className='p-4 bg-yellow-100 text-yellow-900 text-lg mt-3'>
                       Your action is not considered exam malpractice. 
                         However, please make sure to 
                         take the assessment in full-screen mode in order 
                         to follow the assessment instructions.
                     </p>
                 </div>
                <div className='flex flex-wrap md:px-5 justify-center border-t boredr-t-2 border-gray-200 mt-[70px] px-3 py-3'>
                         <button onClick={ reOpenFullScreen }  className='mt-3 md:mt-0 bg-yellow-600 py-3 px-16 rounded-lg text-white text-lg'>
                              Back to Full Screen Mode
                         </button>
                </div>
             </div>
        </div>
    </div>
  )
}

export default ResizeWindowModal