import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import MockAssessment from './components/MockAssessment';

const MockExams = () => {      

  useEffect(() => {
      window.onbeforeunload = function(event) {
            event.preventDefault();
            event.returnValue = '';
          }
     sessionStorage.setItem('active-page-view', 'mock-page')
    }, []);

  return (
    <div className='bg-white'>       
        <section className='jumbotron bg-white p-4'>
            <Link to={'/candidate/assessments/view-assessments'} className='text-lg'>
              Exam
            </Link>
            <span className='mx-2'>/</span>
            <Link to={'/candidate/assessments/view-assessments'} className='text-lg text-customGreen'>
                 View Exams
            </Link>
            <div className='md:flex md:justify-between items-center mt-8'>
         <p className='text-[13px] bg-gray-100 p-3'>
            <span className='bi bi-lightbulb'></span> Tips:
            Did you know that you can take practice exam below with random questions to
            help you prepare better ahead. You can also view your mock exam result once you completed 
            the mock exam.
        </p>
         </div>         
        </section>                    
         <div className='pt-3 pb-12 px-3'>  
             <MockAssessment />
          </div>              
    </div>
  )
}

export default MockExams