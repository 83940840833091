import React from "react";
import useWindowDimensions from "../../../../hooks/useWindowSize";

const Summary = ({summary}) => {
  const { width } = useWindowDimensions();

  return (
    <div className="rounded py-3">
      <div
        className="gap-4"
        style={{
          display: "grid",
          gridTemplateColumns:
            width > 1350
              ? "repeat(4,1fr)"
              : width < 550
              ? "repeat(1,1fr)"
              : "repeat(2,1fr)",
        }}
      >
        {/*TOTAL*/}
        <div className="flex items-center flex-col bg-white border border-slate-300 p-2 rounded-lg">
          <h2
            className="font-bold text-slate-500 w-full rounded-md text-center py-1 mt-1 text-baseFont"
            style={{ backgroundColor: "#f2f3f7", border: "1px solid #ccc" }}
          >
            TOTAL 
          </h2>
          <p className="flex-1 w-full text-center text-5xl font-bold py-4 text-slate-500 flex items-center justify-center">
             {summary?.total}
          </p>
        </div>

        {/*CATEGORY*/}
        <div className="flex items-center flex-col bg-white border border-slate-300 p-2 rounded-lg">
          <h2
            className="font-bold text-customGreen w-full rounded-md text-center py-1 mt-1 text-baseFont"
            style={{ backgroundColor: "#f2f3f7", border: "1px solid #ccc" }}
          >
            COMPLETED
          </h2>
          <p className="flex-1 w-full text-center text-5xl font-bold py-4 text-slate-500 flex items-center justify-center">
            {summary?.completed}
          </p>
        </div>

        {/*TYPE*/}
        <div className="flex items-center flex-col bg-white border border-slate-300 p-2 rounded-lg">
          <h2
            className="font-bold text-customOrange w-full rounded-md text-center py-1 mt-1 text-baseFont"
            style={{ backgroundColor: "#f2f3f7", border: "1px solid #ccc" }}
          >
            TERMINATED
          </h2>
          <p className="flex-1 w-full text-center text-5xl font-bold py-4 text-slate-500 flex items-center justify-center">
              {summary?.terminated}
          </p>
        </div>       
        <div className="flex items-center flex-col bg-white border border-slate-300 p-2 rounded-lg">
          <h2
            className="font-bold text-slate-500 w-full rounded-md text-center py-1 mt-1 text-baseFont"
            style={{ backgroundColor: "#f2f3f7", border: "1px solid #ccc" }}
          >
            NOT STARTED
          </h2>
          <p className="flex-1 w-full text-center text-5xl font-bold py-4 text-slate-500 flex items-center justify-center">
             {summary?.notStarted}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Summary;
