import React, { useState, useEffect } from 'react'
import ScaleLoader_ from '../../components/ScaleLoader'
import ResultModal from './components/ResultModal';
import { Link } from 'react-router-dom'
import { useTimer } from 'react-timer-hook';
import QuestionTypeWrapper from './components/question-types/QuestionTypeWrapper'
import CalculatorModal from '../candidate-assessment/components/CalculatorModal';
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { api } from "./../../../../../api/index";

const CandidatePracticeTest = () => {

  const [ testAssessment, setTestAssessment ] = useState({
     questions: [],
     activeQuestion: [],
     isCompleted: false,
     isTimedOut: false,
     questionNumber: 0,
     isStarted: false,     
     savedAnswers:[]
  })

  const getPracticeQuestionsMutation = useMutation(api.getPracticeTestQuestions,{
      onSuccess: (data) => {

        setTestAssessment({...testAssessment, questions: data?.data?.questions,
                           activeQuestion: data?.data?.questions[0]
        })
      }
  })

  useEffect( () => {
     getPracticeQuestionsMutation.mutate()
  }, [])

  const [openModal, setOpenModal ] = useState(false)
  const [ showResultModal, setShowResultModal ] = useState(false)
  const [ result, setResult ] = useState({ 
        totalCorrect: 0,  total: 0 ,
         notAnswered:0, failed:0
     })

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const submitAssessment = () => {
    
    const savedAnswers = testAssessment.savedAnswers             
    let totalQuestions= 0;
    let totalCorrect = 0;    
    const attemptedQuestions = new Set();
    
    savedAnswers.forEach((savedAnswer) => {
      const { question_id, answer_id } = savedAnswer;
    
      // Check if the question is already counted
      if (!attemptedQuestions.has(question_id)) {
        attemptedQuestions.add(question_id);
    
        // Find the question by ID from the random list of questions
        const question = testAssessment.questions.find((q) => q.id === question_id);
    
        if (question) {
          totalQuestions++;
    
           // Check the question type and compare the answers
      if (question.type === "all correct") {
        const isCorrect = question.answers.every((answer) => {
          // Check if each answer of the question exists in the saved answers array
          const savedAnswer = savedAnswers.find((sa) => sa.question_id === question.id && sa.answer_id === answer.id);
          return savedAnswer && answer.is_correct;
        });

        if (isCorrect) {
          totalCorrect++;
        }
          } else if (question.type === "true or false") {
            const selectedAnswerText = savedAnswer.answer_text.trim().toLowerCase();
    
            // Determine the correct answer based on the answer text
            const correctAnswer = question.answers.find((answer) => answer.text === selectedAnswerText);
    
            if (correctAnswer && correctAnswer.id === answer_id) {
              totalCorrect++;
            }
          } else if (question.type.toLowerCase() === "select") {
            const selectedAnswer = question.answers.find((answer) => answer.id === answer_id);
    
            if (selectedAnswer && selectedAnswer.is_correct) {
              totalCorrect++;
            }
          } else if (question.type === "fill in the blank") {
            const isCorrect = question.answers.every((answer) => {
              // Check if each answer text of the question exists in the saved answers array
              return savedAnswers.some((sa) => sa.question_id === question.id && sa.answer_text.toLowerCase() === answer.text.toLowerCase());
            });
    
            if (isCorrect) {
              totalCorrect++;
            }
          }
          else if (question.type === "multiple correct") {
            const savedAnswerIds = savedAnswers
              .filter((sa) => sa.question_id === question.id)
              .map((sa) => sa.answer_id);
            
            const isCorrect = question.answers.every((answer) => {
              if (answer.is_correct) {
                return savedAnswerIds.includes(answer.id);
              } else {
                return !savedAnswerIds.includes(answer.id);
              }
            });
    
            if (isCorrect) {
              totalCorrect++;
            }
          }
        }
      }
    });
    

    // Get the list of question IDs from the saved answers
    const answeredQuestionIds = savedAnswers.map((savedAnswer) => savedAnswer.question_id);
    // Find the unanswered questions
    const unansweredQuestions = testAssessment.questions.filter((question) => !answeredQuestionIds.includes(question.id));

    setResult({...result, 
      totalCorrect: totalCorrect,
      failed: testAssessment.questions.length - totalCorrect,
      notAnswered:unansweredQuestions.length, 
      total: testAssessment.questions.length 
    })
    setShowResultModal(true)     
        
  }

  const {
    seconds,
    minutes,
    hours,     
    start,
    restart
  } = useTimer({ expiryTimestamp: new Date().getTime() +  10 * 60 * 1000 ,
                 onExpire: () =>  {                                       
                      toast.error('You had reachout your assessment time.')                     
                      submitAssessment()
                    },
                    autoStart: false,
                }); 

      useEffect( () => {
         start()
      }, [])
  
    const moveToNextQuestion = (single_assessment) => {
      window.scrollTo({top: 0, behavior:'smooth'})
      if(testAssessment.questionNumber < testAssessment?.questions?.length - 1 ){
          setTestAssessment({
            ...testAssessment, inEditMode:false,
            activeQuestion : testAssessment?.questions[
                testAssessment.questionNumber + 1
            ],
            questionNumber : testAssessment.questionNumber + 1,
            questionType : testAssessment?.questions[
              testAssessment.questionNumber + 1
          ]['type'],          
          })              
      }       
  }

  const moveToPrevQuestion = (single_assessment) => {
    window.scrollTo({top: 0, behavior:'smooth'})
    if(testAssessment.questionNumber > 0 ){
      setTestAssessment({
         ...testAssessment, inEditMode: true,
         activeQuestion : testAssessment.questions[
            testAssessment.questionNumber - 1
         ],
         questionNumber : testAssessment.questionNumber - 1,
         questionType : testAssessment.questions[
          testAssessment.questionNumber - 1
       ]['type'],      
      })   
             
   }
   window.scroll(0,0)
  }

  return (
    <div className='bg-white px-2 pt-3 pb-5'> 
        { getPracticeQuestionsMutation.isLoading && <ScaleLoader_ /> } 
        { showResultModal && <ResultModal
           setShowResultModal = { setShowResultModal }
           result = { result }
        /> }
        <section className='jumbotron bg-white flex justify-between p-2'>
             <div>
                  <Link to={'/candidate/assessments/view-assessments'} className='text-lg'>
                      Assessement
                  </Link>
                  <span className='mx-2'>/</span>
                  <Link to={'/candidate/assessments/candidate-practice-test'} className='text-lg text-customGreen'>
                      Candidate practice test
                  </Link>
             </div>
             <Link to={'/candidate/assessments/view-assessments'}
              className='px-16 md:mr-5 py-3 rounded-lg bg-red-500 text-white text-sm'>
                     { testAssessment?.questions?.length ? 'Cancel' : 'Back' }
             </Link>
        </section>

        { testAssessment?.questions?.length ? <div className='mt-8 py-12 bg-darkBlue px-5 text-white rounded-lg'>
                     <h5 className='text-2xl flex justify-between'>
                         Candidate Practice Test
                         <span className='text-xl flex items-center'> 
                            <i className='text-2xl bi bi-clock-history mr-2'>
                             </i>  {hours < 10 ? `0${hours}` : hours }: {minutes < 10 ? `0${minutes}` : minutes }: {seconds < 10 ? `0${seconds}` : seconds }
                         </span>
                     </h5> 
                     <p className='text-[12px]'>
                        please attempt all questions.
                     </p>
                     <div className='mt-4 bg-white py-4 rounded-lg text-gray-900 px-4 flex justify-between'>
                          <span> Question { testAssessment.questionNumber + 1 } of 
                             &nbsp; {testAssessment?.questions?.length}
                           </span>
                          <span> Section: &nbsp;{testAssessment?.activeQuestion?.section?.title}</span>
                     </div>
            </div> : null }
              {
                  testAssessment?.activeQuestion?.direction?.title && 
                  <div className='mt-8 mb-5 bg-gray-100 text-lg text-green-500 py-5 px-5 rounded-lg'>
                     {assessment?.activeQuestion?.direction?.title}
                  </div>
                 } 
            <div className='mt-12'>
                  {
                    testAssessment?.questions?.length ?
                    testAssessment?.questions?.map( (single_assessment,index) => {
                        if(testAssessment.questionNumber === index){
                           return <>
                              <h5 className='text-xl mb-3'>
                                 Question (                                                  
                                      <span className=' text-xl px-1'>
                                        { testAssessment.questionNumber + 1 }
                                         </span>        
                                     <span className='text-green-500 text-xl pl-1'>of </span>
                                   &nbsp; {testAssessment?.questions?.length} )
                                </h5>
                              <p 
                                   className='text-[17px]' 
                                   dangerouslySetInnerHTML={{ __html : (single_assessment['text']) }} 
                             /> 
                              <h5 className='text-xl mt-12 mb-3'> Options </h5>                      
                                <QuestionTypeWrapper                                         
                                        single_assessment={single_assessment} 
                                        testAssessment = { testAssessment }
                                        setTestAssessment = { setTestAssessment }
                                 /> 
                              <div className='mt-[130px] flex justify-end items-center'>                                  
                                 <div className='flex ml-[5px] sm:ml-0 justify-between items-center '>                                                                        
                                 <button 
                                    className='bi bi-calculator-fill text-green-900 text-4xl mr-4 md:mr-16'
                                    onClick={ handleOpenModal }
                                    ></button>
                                    { openModal && <CalculatorModal closeModal = { handleCloseModal } />  }
                                 
                                 <button
                                   onClick={ () => moveToPrevQuestion(single_assessment) }
                                 className='py-3 px-8 md:px-16 rounded-lg text-darkBlue  hover:text-white active:text-white hover:bg-darkBlue active:bg-darkBlue    border border-darkBlue text-lg'> 
                                 <i className='bi bi-chevron-double-left mr-3'></i> Prev
                                 </button>
                                    {
                                     testAssessment?.questions.length - 1 !== index ?
                                    <button                             
                                     onClick={ () => moveToNextQuestion(single_assessment)  }
                                     className='py-3 px-8 md:px-16 rounded-lg ml-16 hover:bg-darkBlue hover:text-white active:text-white active:bg-darkBlue text-darkBlue border border-darkBlue text-lg'> 
                                       Next <i className='bi bi-chevron-double-right ml-3'></i>
                                    </button>
                                     :  
                                     <button                             
                                      onClick={ submitAssessment }
                                      id='submit-assessment-btn'
                                      className='py-3 px-8 md:px-16 rounded-lg ml-16 text-white border hover:text-white active:text-white bg-darkBlue border-blue-500 text-lg'> 
                                        Submit <i className='bi bi-chevron-double-right ml-3'></i>
                                     </button>
                                    }                                      
                                 </div>
                              </div>
                       </>
                        }
                    } ) : <div className='pb-12'>
                              <img
                                  src="/images/empty-test-q-a.jpg" alt="empty-test-q-a" 
                                  className='h-[200px] block mx-auto w-[200px] mt-12'
                               />
                              <p className='mt-2 text-center text-xl pb-16'>
                                Sorry! there is no any practice question uploaded yet.
                             </p>
                          </div>
                  }
                </div>
    </div>
    
  )
}

export default CandidatePracticeTest