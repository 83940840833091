import React, { useContext } from 'react'
import { AssessementContext } from "./../../../AssessmentContext";


const PreviewOptionWrapper = ({ answers, type }) => {

    const { assessment, } = useContext( AssessementContext )
    const options = ['A','B','C','D','E']  

  return (
    <div className='md:flex md:flex-wrap'>
      {
        (type === 'yes or no' || type === 'true or false' ) ?
          answers.slice(0,2).map( (answer,id) => {  

            return(
              <div className='flex my-4' key={id}>
                 <span className='flex  text-xl items-center'> 
                    True                          
                        <>
                           { (id === 0 && answer?.status) === true && 
                             <span className='bi bi-check2-circle ml-2 text-2xl text-green-500'></span>
                          }
                        </>
                 </span>
                 <span className='flex text-xl items-center ml-[100px]'>
                   False                       
                      <>
                         {  ( id === 1 && answer?.status === true) && 
                           <span className='bi bi-check2-circle ml-2 text-2xl text-green-500'></span>
                         }
                      </>
               </span>
              </div>
           )

          })
        :
        answers.slice(0,5).map( (answer,id) => {     
         
           if( type === 'fill in the blank' || type === 'matching'){
             
              return(
                  <div className={`w-[200px] py-5 border border-gray-200 rounded flex justify-center items-center mr-8`}>
                      <span className='answer-wrapper text-xl' dangerouslySetInnerHTML={{__html:  
                         answer?.status === true ? answer?.text : ''
                      }} />
                  </div>
              )
           }           
          
            else return(                             
                <div key={id} className={`rounded ${ answer?.status === true ? ' bg-green-100 border border-green-500 text-green-500 py-8 px-4' : ''} md:w-[45%] flex items-center  w-[90%] md:w-[35%] pr-5 ml-5 mb-12`}>                                    
                     <span className='text-xl flex items-center'>
                     <span  className='text-xl mr-2 px-4 py-2 bg-gray-900 text-white'>  
                     {options[id]}                      
                     </span> &nbsp;   
                         <span className='answer-wrapper text-xl' dangerouslySetInnerHTML={{__html: answer.text}} />
                     </span>
                 </div>                                                          
               )
        } )
      }
    </div>
  )
}

export default PreviewOptionWrapper