import { useEffect, useState, useContext } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./custom.css";
import "./App.css";

import "react-datepicker/dist/react-datepicker.css";
import "tw-elements";

import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";
import { CustomToastProvider } from "./components/customtoast/CustomToastProvider";
import ChatwootWidget from "./components/ChatwootWidget/ChatwootWidget";

import CandidateRoutes_ from "./pages/candidate/CandidateRoutes";
import ErrorBoundary from "../src/components/errorBoundary/errorBoundary";
import { UserContext } from "./context/index.js";
// import SignPage from "./pages/account/Signin";
import RegisterPage from "./pages/account/register";
import LoginPage from "./pages/account/login";
import VerifyEmail from "./pages/account/verifyEmail";
import VerifyRegisteredUserEmail from "./pages/account/verifyUserEmail";
import ResetPassword from "./pages/account/resetPassword";
import ForgotPassword from "./pages/account/forgotPassword";
import NotFound from "./notFound";
import NotFoundPage from "./components/404Page/NotFoundPage.jsx";
import RegisterExamPage from "./pages/StandaloneExam/RegisterExamPage";
import RejoinTestPage from "./pages/StandaloneExam/RejoinTestPage";
import EmailVerification from "./pages/account/EmailVerification";
import Verification from "./pages/account/Verification.jsx";
import ReleaseNotes from "./pages/ReleaseNotes/ReleaseNotes.jsx";
import Redirect from "./pages/candidate/redirect/Redirect.jsx";

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const location = useLocation();
  const currentRoute = location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const campaignId = queryParams.get("camapign_id");

  useEffect(() => {
    const userData = sessionStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      setUser((x) => ({ ...x, profile: user }));
    } else if (!userData && currentRoute.includes("/candidate")) {
      if (campaignId !== null)
        sessionStorage.setItem("profile-campaign-id", campaignId);
      navigate("/");
      toast.remove();
      toast("Login to your account");
    }
  }, []);

  const disabled = process.env.REACT_APP_DISABLE_ROUTE;

  return (
    <ErrorBoundary>
      <CustomToastProvider>
        <QueryClientProvider client={queryClient}>
          <UserContext.Provider value={{ user, setUser }}>
            <Routes>
              {/* <Route path="/" element={<SignPage />} /> */}
              <Route
                path="/register"
                element={
                  disabled === "disable-route" ? <NotFound /> : <RegisterPage />
                }
              />
              <Route
                path="/auth/forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                path="/reset-password"
                element={
                  disabled === "disable-route" ? (
                    <NotFound />
                  ) : (
                    <ResetPassword />
                  )
                }
              />
              <Route
                path="/email-verification"
                element={
                  disabled === "disable-route" ? (
                    <NotFound />
                  ) : (
                    <EmailVerification />
                  )
                }
              />
              <Route
                path="/verification"
                element={
                  disabled === "disable-route" ? <NotFound /> : <Verification />
                }
              />
              <Route
                path="/email/verify/:id1/:id2"
                element={<VerifyRegisteredUserEmail />}
              />
              <Route
                path="/application/:slug"
                element={
                  disabled === "disable-route" ? <NotFound /> : <RegisterPage />
                }
              />
              <Route
                path="/application/:slug/:referral"
                element={
                  disabled === "disable-route" ? <NotFound /> : <RegisterPage />
                }
              />
              <Route path="/candidate/*" element={<CandidateRoutes_ />} />
              <Route path="/redirect" element={<Redirect />} />
              <Route path="*" element={<NotFoundPage url={"/"} />} />
              <Route path="/" element={<LoginPage />} />
              <Route path="/exam/:slug" element={<RegisterExamPage />} />
              <Route path="/LR_release_note" element={<ReleaseNotes />} />
              <Route path="/rejoin-exam" element={<RejoinTestPage />} />
            </Routes>
          </UserContext.Provider>
          <ChatwootWidget />
          {/*<ReactQueryDevtools initialIsOpen={false} position="bottom-left" />*/}
        </QueryClientProvider>
        <Toaster
          toastOptions={{
            // style: {
            //   color: "#023047",
            // },
            success: {
              iconTheme: {
                primary: "#90EE90",
              },

              style: {
                color: "#023047",
              },
              duration: 6000,
            },
            error: {
              duration: 5000,
            },
          }}
        />
      </CustomToastProvider>
    </ErrorBoundary>
  );
}

export default App;
