import React, {useState} from "react";
import { api } from "../../../api/index";
import { useQuery } from "react-query";
import UpcomingExams from "./components/UpcomingExams";
import { Link, useNavigate } from "react-router-dom";
import ScaleLoader_ from "../assessments/components/ScaleLoader";
import JobListings from "./components/JobListings";

const Notifications = () => {

  const [userGroupAssessments, setUserGroupAssessments] = useState([])
  const [ jobListings, setJobListings ] = useState([])

   const {isFetching, isLoading} = useQuery(
        "user-group-assessment",
        async () => {
          const data = await api.getAssesments();
          return data;
        },
        {
          onSuccess: (data) => {
             setUserGroupAssessments(
               data?.data?.upcoming_exams?.filter( exam => exam?.require_booking === 0 )?.slice(0,5)
              )
          },
        }
    )

    const {isFetching: isJobsListingFetching, isLoading: isJobListingsLoading } = useQuery(
      "job listings",
      async () => {
        const data = await api.getJobListings();
        return data;
      },
      {
        onSuccess: (data) => {
             setJobListings(data?.data?.data)
        },
      }
  );

  const navigate = useNavigate()
  const handleBack = () => navigate(-1)

  const getRoute = () => {
    const hostname = window.location.hostname;
    if (hostname.includes("playpen") || hostname.includes("localhost")) {
      return "http://playpen.testassessify.com";
    } else {
      return "https://testassessify.com";
    }
  };

  if( isLoading || isJobListingsLoading ) return <ScaleLoader_ />
   
  return (
    <div className="md:-mt-5 bg-white">  
        <div className="pt-4 flex justify-between md:shadow-md py-3 px-2">
           <h2 className="text-2xl pl-1">Notifications</h2>
            <button 
              onClick={handleBack}
              className="bi bi-arrow-left text-md border border-customGreen py-2 px-16 rounded-lg">
              &nbsp;Back
            </button>
        </div>
         <div className="px-4">
         <div className="md:flex px-3 justify-between mt-8">
            {/*<div>
              <h5 className="text-lg mb-5"> Upcoming Exams</h5>
              <UpcomingExams 
                    userGroupAssessments = {userGroupAssessments}
                    isLoading = {isFetching}
              />
              { userGroupAssessments?.length && <div className="flex justify-end mt-4 mb-5">
                   <Link to={'/candidate/assessments/view-assessments'} className="text-md text-customGreen">
                       View More <span className="ml-1 bi bi-arrow-right"></span>
                   </Link>
              </div>}
            </div>*/}
            <div>
              <h5 className="text-lg mb-5">Recent Job Listings</h5>            
               <JobListings 
                  listings = {jobListings}
                  isLoading = { isJobsListingFetching }
               />
                <div>
                    { jobListings?.length && <div className="flex ml-3 mt-4 mb-5">
                      <a target="_blank" href={`${getRoute()}/job-listings`} className="text-md border border-customGreen py-3 px-12 rounded-full text-customGreen">
                          View More <span className="ml-1 bi bi-arrow-right"></span>
                      </a>
                  </div>}
                </div>
            </div>
        </div>
         </div>
    </div>
  );
};

export default Notifications
