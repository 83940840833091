export function detectBrowser(setChecks) {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf("Firefox") !== -1) {
    return setChecks((prev) => {
      return { ...prev, browser: "Firefox" };
    });
  }

  if (userAgent.indexOf("Chrome") !== -1) {
    return setChecks((prev) => {
      return { ...prev, browser: "Chrome" };
    });
  }

  if (userAgent.indexOf("Safari") !== -1) {
    return setChecks((prev) => {
      return { ...prev, browser: "Safari" };
    });
  }

  if (userAgent.indexOf("Edge") !== -1) {
    return setChecks((prev) => {
      return { ...prev, browser: "Edge" };
    });
  }

  return setChecks((prev) => {
    return { ...prev, browser: "Unknown" };
  });
}

export function detectOS(setChecks) {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf("Win") !== -1) {
    return setChecks((prev) => {
      return { ...prev, os: "Windows" };
    });
  }

  if (userAgent.indexOf("Mac") !== -1) {
    return setChecks((prev) => {
      return { ...prev, os: "MacOS" };
    });
  }

  if (userAgent.indexOf("Linux") !== -1) {
    return setChecks((prev) => {
      return { ...prev, os: "Linux" };
    });
  }

  return setChecks((prev) => {
    return { ...prev, os: "Unknown" };
  });
}

export function detectBrowserVersion(setChecks) {
  const userAgent = window.navigator.userAgent;

  let version;

  if (userAgent.indexOf("Firefox") !== -1) {
    version = userAgent.split("Firefox/")[1];
    return setChecks((prev) => {
      return { ...prev, browserVersion: version };
    });
  }

  if (userAgent.indexOf("Chrome") !== -1) {
    version = userAgent.split("Chrome/")[1].split(" ")[0];
    return setChecks((prev) => {
      return { ...prev, browserVersion: version };
    });
  }

  if (userAgent.indexOf("Safari") !== -1) {
    version = userAgent.split("Version/")[1].split(" ")[0];
    return setChecks((prev) => {
      return { ...prev, browserVersion: version };
    });
  }

  if (userAgent.indexOf("Edge") !== -1) {
    version = userAgent.split("Edge/")[1];
    return setChecks((prev) => {
      return { ...prev, browserVersion: version };
    });
  }

  return setChecks((prev) => {
    return { ...prev, browserVersion: "unknown" };
  });
}

export function detectScreenResolution(setChecks) {
  return setChecks((prev) => {
    return {
      ...prev,
      screenRes: window.screen.width > 350  ? `${window.screen.width}x${window.screen.height}`
               : 'unknown'
      ,  
    };
  });
}

export function detectJSEnabled(setChecks) {
  if (typeof window === "object") {
    return setChecks((prev) => {
      return {
        ...prev,
        javascript: `Enabled`,
      };
    });
  } else {
    return setChecks((prev) => {
      return {
        ...prev,
        javascript: ` Not enabled`,
      };
    });
  }
}
