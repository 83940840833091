import React from 'react'


const BooleanQuestions = ({question, testAssessment, setTestAssessment}) => {
  

  const handleSaveAnswer = (event) => {
    
    const savedAnswer = (answers, _updatedAnswer) => {
      
      const updatedAnswer = {
        answer_id : _updatedAnswer?.id ,
        question_id: _updatedAnswer?.question_id,
        answer_text: event.target.value,                         
        category_id: question?.category_id,
        section_id: question?.section_id,
        is_correct: _updatedAnswer?.is_correct,  
      }

      const index = answers.findIndex(obj => obj.question_id === updatedAnswer.question_id);
      if (index >= 0) {
        const updatedAssessment = [...answers];
        updatedAssessment[index] = updatedAnswer;
        return updatedAssessment;
      } else {
        return [...answers, updatedAnswer];
      }
  
    }

    setTestAssessment(prevAssessment => {
       return {...prevAssessment, 
        savedAnswers : 
         [...savedAnswer(prevAssessment.savedAnswers, question.answers[0])]}
     })

    }

    const isSelectedAnswer = (answer) => {

      const updatedAnswer = {
        answer_id : answer?.id ,
        question_id: answer?.question_id,            
      }
    
      const index = testAssessment.savedAnswers.findIndex(obj =>  {            
           return (obj.question_id === updatedAnswer.question_id) &&  (obj.answer_id === updatedAnswer.answer_id)     
     });
    
     if ((index >= 0)){                
        return  parseInt(testAssessment.savedAnswers[index]?.answer_text) ? '1':'0'
     }   
     else return false;
    }

  return (
    <div >   
        <form className={`form-${question.id} flex  px-3`}>
             <div className='flex items-center w-[50%] md:w-[35%] pr-5 ml-5'>
                   <label htmlFor="yes" className='text-lg mr-2'> 
                     {testAssessment?.activeQuestion?.type === 'true or false'?
                        'True': 'Yes'
                     } 
                   </label>
                   <input 
                       type="radio" name="answer" id="yes"  
                       className='w-[16px] h-[16px] green-radio' 
                       value={'1'}       
                       defaultChecked = { 
                        isSelectedAnswer(question.answers[0]) === '1' ? true : false                                         
                        }               
                       onClick={ handleSaveAnswer }
                    />
             </div>
             <div className='flex items-center w-[50%] md:w-[35%] pr-5 ml-5'>
                   <label htmlFor="no" className='text-lg mr-2'> 
                     {testAssessment?.activeQuestion?.type === 'true or false'?
                        'False': 'No'
                     } 
                   </label>
                   <input 
                       type="radio" name="answer" id="no" 
                       value={'0'} 
                       defaultChecked = { 
                        isSelectedAnswer(question.answers[0]) === '0' ? true : false                                         
                        }
                       onClick={handleSaveAnswer}
                       className='w-[16px] h-[16px] green-radio'
                    />
             </div>
        </form>
    </div>
  )
}

export default  React.memo(BooleanQuestions)