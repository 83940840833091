import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Alonehere from "./../src/assets/icons/alonehere.svg";
import logo from "./images/TestAssessify.png";

function NoAccess() {
  return (
    <div className="relative bg-white h-full grid place-content-center">
      {/* <div className="w-full p-3 h-26  sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div> */}

      <div className="">
        <div className="relative z-10 flex flex-col items-center w-full font-mono">
          <p className="font-extrabold text-primary text-8xl  animate-bounce italic">
            Oops!
          </p>
          <h1 className="mt-4 text-5xl font-bold leading-tight text-center text-primary mb-10">
            You don't have access to view this page
          </h1>

          <Link
            to="/candidate/assessments/view-assessments"
            className="text-darkBlue text-xl border rounded-lg border-primary p-4"
          >
            Go back home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NoAccess;
