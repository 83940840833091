import React, { useState } from "react";
import toast from "react-hot-toast";
import { api } from "../../api";

function ChangePasswordForm() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    } else if (newPassword.length < 8) {
      toast.error("The new password field must be at least 8 characters");
      return;
    }

    let payload = {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: confirmPassword,
    };

    try {
      toast.loading("loading...");
      const res = await api.changePassword(payload);
      toast.remove();
      toast.success(res.message);
    } catch (error) {
      toast.remove();

      toast.error(error.response.data.error);
    }
    // Reset form fields
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div>
      <h2 className="text-xl font-semibold text-gray-400">Change Password</h2>
      <form
        onSubmit={handleSubmit}
        className="max-w-full border rounded-lg p-4 mt-5"
      >
        <div className="mb-4">
          <label
            htmlFor="currentPassword"
            className="block mb-2 font-bold text-gray-400"
          >
            Current Password
          </label>
          <input
            type="password"
            id="currentPassword"
            className="w-full p-2 border border-gray-300 rounded  text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#88a848]  focus:border-transparent"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="newPassword"
            className="block mb-2 font-bold text-gray-400"
          >
            New Password
          </label>
          <div className="relative">
            <input
              type={showNewPassword ? "text" : "password"}
              id="newPassword"
              className="w-full p-2 border border-gray-300 rounded  text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#88a848]  focus:border-transparent"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={handleToggleNewPasswordVisibility}
              className="absolute top-2 right-2"
            >
              {showNewPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19.07 12.5a10.9 10.9 0 01-2.54 3.47M4.93 12.5A10.9 10.9 0 0112 8.03M2.75 2.75l18.5 18.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="confirmPassword"
            className="block mb-2 font-bold text-gray-400"
          >
            Confirm Password
          </label>
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              className="w-full p-2 border border-gray-300 rounded  text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#88a848]  focus:border-transparent"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={handleToggleConfirmPasswordVisibility}
              className="absolute top-2 right-2"
            >
              {showConfirmPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19.07 12.5a10.9 10.9 0 01-2.54 3.47M4.93 12.5A10.9 10.9 0 0112 8.03M2.75 2.75l18.5 18.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <button
          type="submit"
          className="bg-primary hover:bg-primary/70 text-white font-bold py-2 px-4 rounded"
        >
          Change Password
        </button>
      </form>
    </div>
  );
}

export default ChangePasswordForm;
