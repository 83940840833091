export const exitFullScreen = () => {
    const  isFullScreen = () => {
        return (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
        );
      }
     if( isFullScreen() ){
        if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
     }
  }
 
  export const tranformToUniqueData = (originalArray) => {
      // Use a Set to keep track of unique question_id values
       const uniqueQuestionIds = new Set();
        // Filter the original array to get unique items based on question_id
        const uniqueArray = originalArray.filter(item => {
        // Check if the question_id is not in the Set
        if (!uniqueQuestionIds.has(item.question_id)) {
            // Add the question_id to the Set
            uniqueQuestionIds.add(item.question_id);
            // Return true to include this item in the filtered array
            return true;
        }
            // Return false to exclude this item from the filtered array
             return false;
        });
      return uniqueArray
  }

  export const toPlainAnwers = (questions) => {
     let answers = []
     questions?.forEach( question => {
      question?.answers?.forEach( answer => {
         answers.push({
             question_id: question?.id,
             answer_id: answer?.id,
             answer_text: answer?.text,
             status:  answer?.status
         })
      })
   })
   return answers.filter( ans => ans?.status === true )
  }