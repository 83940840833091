import React from 'react'

const Section = ({ data = [] }) => {
  return (
    <section className='md:flex justify-between mb-4'>
            <div className='md:w-[48%]'>
                <span className=' text-md'>{data[0]?.title}: </span>
                <span className='text-md text-gray-500'>{data[0]?.value}</span>
            </div>
            <div className='md:w-[48%]'>
                <span className='text-md'>{data[1]?.title}: </span>
                <span className='text-md text-gray-500'>{data[1]?.value}</span>
            </div>
    </section>
  )
}

export default Section