import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquarePollVertical,
  faTimeline,
  faQuestion,
  faGears,
  faTableColumns,
  faUser,
  faMagnifyingGlass,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";

export const pageRoutes = {
  guest: [
    {
      id: 1,
      title: "Profile",
      icon: <FontAwesomeIcon icon={faTableColumns} />,
      link: "/candidate/profile",
    },
  ],
  candidate: [
    {
      id: 1,
      title: "Dashboard",
      icon: <FontAwesomeIcon icon={faTableColumns} />,
      link: "/candidate/dashboard",
    },
    {
      id: 2,
      title: "Practice Exams",
      icon: <FontAwesomeIcon icon={faSquarePollVertical} />,
      link: "/candidate/assessments/view-practice-exams",
    },
    {
      id: 3,
      title: "Exams",
      icon: <FontAwesomeIcon icon={faSquarePollVertical} />,
      link: "/candidate/assessments/view-assessments",
    },
    {
      id: 4,
      title: "Result History",
      icon: <FontAwesomeIcon icon={faTimeline} />,
      link: "/candidate/exam-history",
    },
    {
      id: 5,
      title: "My Profile",
      icon: <FontAwesomeIcon icon={faUser} />,
      link: "/candidate/profile",
    },
    {
      id: 6,
      title: "Help & Documentation",
      icon: <FontAwesomeIcon icon={faQuestion} />,
      link: "/candidate/documentation",
    },
    // {
    //   id: 4,
    //   title: "Vacancies",
    //   icon: <FontAwesomeIcon icon={faToolbox} />,
    //   link: "/candidate/vacancies",
    // },

    {
      id: 6,
      title: "Settings",
      icon: <FontAwesomeIcon icon={faGears} />,
      link: "/candidate/settings",
      info: "View profile",
    },
  ],
};
