import React, {useContext} from 'react';
import { Modal, Ripple, initTE } from "tw-elements";
import { AssessementContext } from '../../../AssessmentContext';
import { useSelector } from 'react-redux';

const SubmitConfirmModal = ({ id, submitAssessment, lastQuestion }) => {

    const { assessment, setAssessment } = useContext(  AssessementContext )
    const questions = assessment?.assessements?.questions;
    const { examQuestions } = useSelector( state => state.candidateExam)

   React.useEffect(() => {
     initTE({ Modal, Ripple });
   }, [])

  return (
    <>  
       <div
        data-te-modal-init
        className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id={id}
        tabIndex="-1"         
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog">
        <div
            data-te-modal-dialog-ref             
            className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
            <div
            style={{ backgroundColor: '#fff'}}
            className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div
                style={{ backgroundColor: '#fff'}}
                className="flex flex-shrink-0 items-center shadow justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                
                <h5
                className="text-2xl font-medium leading-normal text-gray-900"
                id="exampleModalScrollableLabel">
                   Preview Exam
                </h5>
                
                <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="h-6 w-6">
                    <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12" />
                </svg>
                </button>
            </div>
            
            <div className="relative p-4">
                <h1 className='text-center text-gray-700 mt-3 text-7xl bi bi-recycle'></h1>
                { questions?.length > examQuestions?.length ? 
                <p className="text-xl text-red-500 text-center mt-2">
                   You had { 
                        questions?.length - examQuestions?.length
                   } questions that are not answered?
                </p> : null }
                <p className="text-xl text-center mt-2">
                    Did you want to preview exam before you submit?
                </p>
                <p className='mt-4 p-3 text-lg text-yellow-800 italic bg-yellow-100'>
                  <span className='bi bi-lightbulb text-2xl'></span> Tips:
                   Please ensure that each question is answered,
                   you can preview your question answered make adjustment
                   and then submit.
                </p>
            </div>              
            
            <div
                className="flex flex-shrink-0   items-center justify-between md:justify-end rounded-b-md border-t-2 border-gray-200 border-opacity-100 p-4 ">
                <button
                type="button"
                className="inline-block md:mr-5 rounded text-white bg-green-600 px-2 md:px-6 py-3 text-lg font-medium  leading-normal  transition duration-150 ease-in-out hover:bg-green-accent-100 focus:bg-green-accent-100 focus:outline-none focus:ring-0 active:bg-green-accent-200"
                data-te-modal-dismiss
                data-te-ripple-init
                data-te-ripple-color="light"
                onClick={ () => {
                    document.querySelector('#preview-modal-fullscreen').click()
                    //getPreviewQuestions()
                } }
                >
                  Yes, preview my answers
                </button>

                <button
                type="button"
                onClick={submitAssessment}
                className="ml-1 inline-block rounded bg-darkBlue px-2 md:px-6 py-3 text-lg font-medium  leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                data-te-ripple-init
                data-te-modal-dismiss
                data-te-ripple-color="light">
                  No, just submit
                </button>
            </div>
          </div>
       </div>
    </div>
    </>
  )
}

export default SubmitConfirmModal