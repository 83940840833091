import React from 'react'
import { useNavigate } from 'react-router-dom'
import { exitFullScreen } from './utils/functions'

const NetworkErrorModal = () => {

const navigate = useNavigate() 

const logout = () => {
    exitFullScreen()
    navigate('/')
}

  return (
    <div className='h-[100vh] w-[100vw] bg-[rgba(0,0,0,0.3)] fixed top-0 left-0' style={{ zIndex:9000000}}>
        <div className='w-full h-full flex items-center justify-center'>
             <div className='modal-conetent bg-white w-[96%] md:w-[50%] h-[360px] rounded-lg'>
                  <h5 className='py-5 px-3 mb-4 md:mb-5 shadow-lg text-xl'> Network Error </h5>
                 <div className='w-[80%] mx-auto h-[210px] mt-4 calc'>
                     <p className='flex justify-center'>
                        <span className='text-[100px] bi bi-router text-red-500'>
                        </span>
                     </p>
                     <p className='text-xl text-center -mt-12 md:mt-0'>
                        There is seems to be an issue the your network or internet connectivity
                        or the server itself. Ensure
                        that you have a better internet connection, logout and try again. 
                     </p>
                 </div>
                <div className='flex justify-center border-t boredr-t-2 border-gray-200 mt-5 px-3 py-3'>
                         <button onClick={logout}  className='bg-red-500 py-3 px-16 rounded-lg text-white text-lg'>
                              Logout
                        </button>
                </div>
             </div>
        </div>
    </div>
  )
}

export default NetworkErrorModal