import React, { useState } from "react";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToolbox } from "@fortawesome/free-solid-svg-icons";

import { api } from "../../../api";
function Vacancies() {
  const [resultsDisplayLimit, setResultsDisplayLimit] = useState(6);
  const { data, refetch } = useQuery("get-vacancies", api.getVacancies);

  const increaseDisplayLimit = () => {
    setResultsDisplayLimit(resultsDisplayLimit + 6);
  };

  const handleApply = async (vacancy) => {
    let payload = {
      //   slug: sessionStorage.getItem("slug"),
      slug: vacancy.slug,
      referral: sessionStorage.getItem("referral") || "" || undefined || null,
    };

    try {
      toast.loading("loading...");
      const res = await api.jobApplication(payload);
      toast.remove();
      toast.success(res.data);
    } catch (error) {
      toast.remove();
      toast.error(error.response.data.error);
      // toast.error(error.response.error);
      // toast.error(error.response.data.message);
    }
  };
  return (
    <div className="max-w-[1368px] bg-white mt-20 p-4 md:p-16 rounded-lg">
      <div className="font-semibold text-2xl text:darkBlue   gap-x-24 ">
        Jobs feed
      </div>

      <div className="">
        {data?.data?.slice(0, resultsDisplayLimit).map((vacancy, id) => (
          <div
            key={vacancy.id}
            className="mt-10 flex justify-between items-center"
          >
            <div>
              <div className="font-semibold text-xl">{vacancy.title}</div>
              <div className="font-normal text-base text-gray-400">
                {vacancy.client?.name}
              </div>
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => handleApply(vacancy)}
                className="bg-primary text-white px-8 py-4 font-medium text-base rounded"
              >
                Apply
              </button>
            </div>
          </div>
        ))}
        <div className="flex  justify-center">
          {resultsDisplayLimit < data?.data?.length && (
            <div className="  w-46 mt-20 bg-primary rounded-full">
              <button
                className="text-white p-6 text-xl font-medium flex justify-end"
                onClick={increaseDisplayLimit}
              >
                Show more vacancies
              </button>{" "}
            </div>
          )}
        </div>
      </div>
      <div className="grid place-items-center h-full">
        {data?.data?.length === 0 && (
          <div className="flex flex-col justify-center">
            <FontAwesomeIcon icon={faToolbox} className="text-6xl" />
            <p className="pt-6">No jobs available currently</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Vacancies;
