import React, {useState} from 'react'
import { Link, useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import { api } from '../../../api';
import ScaleLoader_ from '../assessments/components/ScaleLoader';
import Section from './components/Section';
import ViewResultModal from './components/ViewResultModal';

const ExamResult = () => {

  const [openModal, setOpenModal] = useState(false)
  const [activeResultId, setActiveResultId] = useState(null)

  const {
    isLoading,
    data: data,
  } = useQuery(
    "user-group-assessment",
    async () => {
      const data = await api.getMockExamsResult({
        groupId: sessionStorage.getItem('group_id'),
        userGroupId: sessionStorage.getItem('user_group_id'),
      });
      return data;
    }, {}
  );

  if(isLoading) return <ScaleLoader_ />

  return (
    <section>  
        <header className='bg-white px-3 py-5 -mt-2 rounded-lg flex justify-between'>
             <h5 className='text-lg'>
                 Mock Exam Result Details
             </h5>
             <Link 
               to={'/candidate/assessments/view-practice-exams'} 
               className='bi bi-arrow-right text-primary text-2xl'
             />
        </header>
        <div className='bg-white p-5 mt-5 rounded-lg'>
          <h4 className='text-lg pb-3 border-b border-gray-400 mb-4'> Candidate Info</h4>
        <section>
            <Section 
               data={[
                  { title: 'Name', value: data?.data?.user?.name },
                  { title: 'Email Address', value: data?.data?.user?.email }
               ]}
             />
             <Section 
               data={[
                  { title: 'Exam Started At', value: `${new Date(data?.data?.started_at)}` },
                  { title: 'Completed At', value: `${new Date(data?.data?.completed_at)}` }
               ]}
             />
             <Section 
               data={[
                  { title: 'Time Allowed', value: data?.data?.time_allowed },
                  { title: 'Time Spend', value: data?.data?.time_spent }
               ]}
             />
             <Section 
               data={[
                  { title: 'Ip Address', value: data?.data?.start_security_log?.ip_address },
                  { title: 'User Agent', value: data?.data?.start_security_log?.user_agent }
               ]}
             />
        </section> 
        </div>  
        { openModal && 
         <ViewResultModal 
         setOpenModal={setOpenModal}
         activeResultId={activeResultId}
       />}
        <section className='bg-white mt-5'>
        <h5 className='text-lg pl-2 pb-2 pt-3'>My Results</h5>
        <div class="flex flex-col overflow-x-auto md:overflow-x-hidden">
              <div class="sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div class="overflow-x-auto md:overflow-x-hidden w-[120vw] md:w-full">
                    <table class="min-w-full text-left border border-gray-200 text-sm font-light">
                      <thead class="shadow">
                        <tr>                          
                          <th scope="col" className="px-6 font-normal py-6">S/N</th>
                          <th scope="col" className="px-6 font-normal py-6">Category</th>
                          <th scope="col" className="px-6 font-normal py-6">Status</th>
                          <th scope="col" className="px-6 font-normal py-6">Created At</th>  
                          <th scope="col" className="px-6 font-normal py-6">Action</th>              
                        </tr>
                      </thead>
                      <tbody>
                           {
                             data?.data?.results?.length ?
                               data?.data?.results?.map( (result, id) => {
                                  return (
                                    <tr class="border-b border-gray-200" key={id}>                             
                                    <td class="whitespace-normal px-6 py-4">{ id + 1}</td>
                                    <td class="whitespace-normal px-6 py-4">{result?.category?.title}</td>
                                    <td class="whitespace-normal px-6 py-4">
                                        {
                                          result?.status === 'fail' ?
                                          <span className='text-red-500 border border-red-500 rounded bg-red-100 py-1 px-5'>
                                             {result?.status}
                                           </span> :
                                           <span className='text-primary border border-primary rounded bg-green-100 py-1 px-5'>
                                           {result?.status}
                                         </span>
                                        }
                                     </td> 
                                    <td class="whitespace-normal px-6 py-4">
                                      {`${new Date(data?.data?.created_at).toLocaleDateString('en-ng')}`}
                                    </td>
                                    <td class="whitespace-normal px-6 py-4">
                                       <button 
                                           className='py-3 px-5 text-sm bg-primary text-white rounded-lg'
                                           onClick={ () => {
                                              setActiveResultId(result?.id)
                                              setOpenModal(true)
                                           } }
                                        >
                                           View Result
                                       </button>
                                    </td>     
                                  </tr>  
                                  )
                               } )
                             : null 
                           }                                                                                                                   
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           </div>
        </section>   
    </section>
  )
}

export default ExamResult