import React from 'react'
import Calculator from "awesome-react-calculator";

const CalculatorModal = ({closeModal}) => {
  return (
    <div className='h-[100vh] w-[100vw] bg-[rgba(0,0,0,0.3)] fixed top-0 left-0' style={{ zIndex:9000000}}>
        <div className='w-full h-full flex items-center justify-center'>
             <div className='modal-conetent bg-white w-[94%] md:w-[35%] h-[410px] rounded-lg'>
                  <h5 className='py-5 px-3 mb-5 shadow-lg text-xl'> Calculator </h5>
                 <div className='w-[80%] mx-auto h-[260px] mt-4 calc'>
                     <Calculator />
                 </div>
                <div className='flex justify-end border-t boredr-t-2 border-gray-200 mt-5 px-3 py-3'>
                         <button  onClick={closeModal}  className='bg-red-500 py-3 px-16 rounded-lg text-white text-lg'>
                              Close
                        </button>
                </div>
             </div>
        </div>
    </div>
  )
}

export default CalculatorModal