 import React from 'react'
 
 const AssessmentResult = () => {
   return (
     <section>
         AssessmentResult
     </section>
   )
 }
 
 export default AssessmentResult