import classes from "./Sidebar.module.css";
import { Link } from "react-router-dom";

const SidebarItem = ({ title, clicked, icon, label, active, path, count }) => {
  return (
    <li className="text-baseFont">
      <Link
        id={label}
        to={path}
        onClick={clicked}
        className={classes.SidebarItem}
        data-active={active}
        title={title}
      >
        {icon} <p className="text-baseFont ml-3 ">{label}</p>
        {count && (
          <span
            className="border border-customOrange px-2 text-white bg-customOrange rounded-md"
            style={{ position: "absolute", right: "7px", fontWeight: "normal" }}
          >
            {count}
          </span>
        )}
      </Link>
    </li>
  );
};

export default SidebarItem;
