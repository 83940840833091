 /* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useQuery, useMutation } from "react-query";
import { api } from "../../../../api";
import toast from "react-hot-toast";
import { useCustomToast } from "../../../../components/customtoast/CustomToastProvider";
import CustomDropdown from "../../../../components/Global/CustomDrpdown";
import { ScaleLoader } from 'react-spinners';

const useDebounce = (fn, delay) => {
  let timeout;
  // return function that takes arg and apply to the function
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

function EditEducationModal({
  closeModal,
  data,
  refetchEducation,
  refetchStatus,
}) {
  const customToast = useCustomToast();

  const [institutionSearch, setInstitutionSearch] = useState('')
  const [institutions, setInstitutions ] = useState([])
  const [searchInstitutionLoading, setSearchInstitutionLoading] = useState(false)
  const [searchCourseLoading, setSearchCourseLoading] = useState(false)
  const [courses, setCourses] = useState([]);

  const { isLoading: institutionLoading, refetch } = useQuery(
    "get-institution",
     () => {
         return api.getInstitutions(institutionSearch)
     },
    {
      onSuccess: response => {
          setInstitutions(response?.data?.map( ins => {
             return {label: ins?.name, value: ins?.id}
          }))
      }
    }
  );
 
  const getInstitutionsMutation = useMutation(api.getInstitutions,
    {
      onSuccess: response => {
          setInstitutions(response?.data?.map( ins => {
             return {label: ins?.name, value: ins?.id}
          }))
      }
    }
  )

  const { isLoading,} = useQuery("get-courses", api.getCourses, {
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
     onSuccess: data => {
      setCourses(
        data?.data?.map((ins) => {
          return { label: ins?.title, value: ins?.id };
        })
      );
     }
  });

  const getCoursesMutation = useMutation(api.getCourses, {
    onSuccess: (response) => {
      setCourses(
        response?.data?.map((ins) => {
          return { label: ins?.title, value: ins?.id };
        })
      );
      setTimeout( () => setSearchCourseLoading(false) , 100 )
    },
  });

  const { data: degrees } = useQuery("get-degree", api.getDegrees);

  const { data: classOfDegrees } = useQuery(
    "get-classOfDegrees",
    api.getClassOfDegrees
  );

  const [startDate, setStartDate] = useState(data?.start_date);
  const [endDate, setEndDate] = useState(data?.end_date);

  // handle seletced states
  const [selectInstitution, setSelectedInstitution] = useState(data?.institution?.name);
  const [selectCourse, setSelectedCourse] = useState(data?.course?.title);
  const [selectDegree, setSelectedDegree] = useState(data?.degree?.name);
  // const [selectState, setSelectedState] = useState(data?.location);
  const [selectClassOfDegree, setSelectedClassOfDegree] = useState(
    data?.class_of_degree?.name
  );
  const [degreeId, setDegreeId] = useState(data?.degree?.id);
  const [courseId, setCourseId] = useState(data?.course?.id);
  const [selectInstitutionId, setSelectedInstitutionId] = useState(data?.institution?.id);
  const [classOfDegreeId, setClassOfDegreeId] = useState(
    data?.class_of_degree?.id
  );

  const [institutionValid, setInstitutionValid] = useState(true);
  const [courseValid, setCourseValid] = useState(true);
  const [degreeValid, setDegreeValid] = useState(true);
  const [classofDegreeValid, setClassOfDegreeValid] = useState(true);
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);

  const filteredDegree = (inputDegree) => {
    return degrees?.data
      .filter((degree) =>
        degree.name.toLowerCase().includes(inputDegree.toLowerCase())
      )
      .map((deg) => ({
        value: deg.id,
        label: deg.name,
      }));
  };

  const filteredCourses = (inputCourse) => {
    return courses?.data
      .filter((course) =>
        course.title.toLowerCase().includes(inputCourse.toLowerCase())
      )
      .map((c) => ({
        value: c.id,
        label: c.title,
      }));
  };
  const courseOptions = (inputCourse, callback) => {
    setTimeout(() => {
      callback(filteredCourses(inputCourse));
    }, 200);
  };

  const filteredclassOfDegrees = (inputClassOfDegree) => {
    return classOfDegrees?.data
      .filter((classOfDegree) =>
        classOfDegree.name
          .toLowerCase()
          .includes(inputClassOfDegree.toLowerCase())
      )
      .map((cg) => ({
        value: cg.id,
        label: cg.name,
      }));
  };

  const classOptions = (inputClassOfDegree, callback) => {
    setTimeout(() => {
      callback(filteredclassOfDegrees(inputClassOfDegree));
    }, 200);
  };

  const updateEducation = async (e) => {
    e.preventDefault();

    setInstitutionValid(selectInstitution !== "");
    setCourseValid(selectCourse !== "");
    setDegreeValid(selectDegree !== "");
    setClassOfDegreeValid(selectClassOfDegree !== "");
    setStartDateValid(startDate !== "");
    setEndDateValid(endDate !== "");

    if (
      !institutionValid ||
      !courseValid ||
      !degreeValid ||
      !classofDegreeValid ||
      !startDate ||
      !endDate
    ) {
      toast.error("All fields are required");
      return;
    }
    else if(new Date(startDate).getTime() > new Date(endDate).getTime() ){
      toast.error('Start date cannot be more than end date.')
      return;
    }

    let formData = {
      name: selectInstitution,
      degree: selectDegree,
      degree_id: degreeId,
      course: selectCourse,
      course_id: courseId,
      // location: selectState,
      class_of_degree: selectClassOfDegree,
      class_of_degree_id: classOfDegreeId,
      start_date: startDate,
      end_date: endDate,
      institution: selectInstitution,
      institution_id: selectInstitutionId
    };

    try {
      toast.loading("loading...");
      const response = await api.updateUserEducation(data.id, formData);
      toast.remove();
      customToast.success(response.message);
      await refetchEducation();
      await refetchStatus();
      closeModal(false);
    } catch ({ response }) {
      toast.remove();
      customToast.error(response?.data?.message);
    }
  };

  const deleteEducation = async (e) => {
    if (confirm("Are you sure you want to delete this")) {
      try {
        const res = await api.deleteUserEducation(data.id);
        customToast.success(res?.message);
        await refetchEducation();
        await refetchStatus();
        closeModal(false);
      } catch ({ response }) {
        toast.remove();
        customToast.error(response?.data?.message);
      }
    }
  };

  const debouncedSearch = useDebounce((input) => {
    getInstitutionsMutation.mutate(input);
    setInstitutionSearch(input);
  }, 1500);

  const handleSearch = React.useCallback(
    (value) => {
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const courseDebouncedSearch = useDebounce((input) => {
    getCoursesMutation.mutate(input);
    setInstitutionSearch(input);
  }, 1500);

  const handleCourseSearch = React.useCallback(
    (value) => {
      courseDebouncedSearch(value);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    if (getInstitutionsMutation.isLoading) {
       setSearchInstitutionLoading(true)
    } else {
      setSearchInstitutionLoading(false)
    }
  }, [getInstitutionsMutation.isLoading]);

  useEffect(() => {
    if (getCoursesMutation.isLoading) {
       setSearchCourseLoading(true)
    } else {
      setSearchCourseLoading(false)
    }
  }, [getCoursesMutation.isLoading]);

  return (
    <div className="fixed z-[100000] top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-50">
    <div className="flex items-center justify-center h-full w-full">
      <div className="w-[96%] overflow-y-scroll z-[100000] h-[500px] md:h-[450px] md:w-[600px] bg-white rounded-[15px] p-8 md:p-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Edit Education 
          </h3>
          <div className="flex justify-between mb-4">
            <div className="text-darkBlue text-xs font-medium"></div>
            <div
              className="text-[#F35B24] text-xs font-medium cursor-pointer"
              onClick={deleteEducation}
            >
              Remove option
            </div>
          </div>
          
          <form onSubmit={updateEducation}>
            <div className="mb-4 relative">
            {  searchInstitutionLoading &&                 
                      <div className="absolute text-xs z-[10000000000000] bg-white w-full py-3 text-center top-[70px]">
                         <div className="w-full h-full flex justify-center items-center">
                            <ScaleLoader color="#87AA4B" className="" height={10} width={10}/>
                         </div>
                      </div>
              }
              <label
                htmlFor="gender"
                className="text-gray-500 mb-4 text-base font-medium"
              >
                Name of Institution  
                <span className="text-red-500 required-dot">*</span>
              </label>
              <CustomDropdown
                 value={
                   selectInstitution
                    ? { value: selectInstitution, label: selectInstitution } // Provide both value and label
                    : null
                  }
                  width={'w-full'}
                  options={
                    institutionLoading ?
                    [{label: 'Searching...', value: ''}] : institutions
                  }
                  onSearch={ (input) => {
                    setSearchInstitutionLoading(true)
                    handleSearch(input);
                   }}
                  onChange={(selectedOption) => {
                    // setSelectedInstitutionId(selectedOption?.value || "");
                    setSelectedInstitution(selectedOption?.label || "");
                    setSelectedInstitutionId(selectedOption?.value)
                    setInstitutionValid(!!selectedOption);
                  }}
                  noOptionsMessage={() => institutionLoading ? 'Searching...' : "No results found"} 
              />
              {!institutionValid && (
                <p className="text-red-500 text-xs mt-1 text-right">
                  Please select an institution
                </p>
              )}
            </div>

            <div className="mb-4 md:flex gap-x-4">
              <div className="mb-4 md:mb-0 w-full md:w-2/3 relative">
              {  searchCourseLoading &&                 
                      <div className="absolute text-xs z-[10000000000000] bg-white w-full py-3 text-center top-[70px]">
                         <div className="w-full h-full flex justify-center items-center">
                            <ScaleLoader color="#87AA4B" className="" height={10} width={10}/>
                         </div>
                      </div>
                      }
                <label
                  htmlFor="gender"
                  className="text-gray-500 mb-4 text-base font-medium"
                >
                  Courses
                  <span className="text-red-500 required-dot">*</span>
                </label> 
                <CustomDropdown
                   options={courses}
                   value={
                    courseId
                      ? { value: courseId, label: selectCourse } // Provide both value and label
                      : null
                  }
                  width={'w-[100%]'}
                  onChange={(selectedOption) => {
                    setCourseId(selectedOption?.value || "");
                    setSelectedCourse(selectedOption?.label || "");
                    setCourseValid(!!selectedOption);
                  }}
                  onSearch={(input) => {
                    setSearchCourseLoading(true)
                    handleCourseSearch(input);
                  }}
                />
                {!courseValid && (
                  <p className="text-red-500 text-xs mt-1 text-right">
                    Please select a course
                  </p>
                )}
              </div>
              <div className="mb-4 md:mb-0 w-full md:w-1/3">
                <label
                  htmlFor="degree"
                  className="text-gray-500 mb-4 text-base font-medium"
                >
                  Degree
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <CustomDropdown
                    options={ 
                      degrees?.data?.length ? 
                      degrees?.data?.map( degree => {
                        return { label: degree?.name , value : degree?.id }
                      } ) : []
                    }
                    value={
                      degreeId
                        ? { value: degreeId, label: selectDegree } // Provide both value and label
                        : null
                    }
                    width={'w-full'}
                    onChange={(selectedOption) => {
                      setDegreeId(selectedOption?.value || "");
                      setSelectedDegree(selectedOption?.label || "");
                      setDegreeValid(!!selectedOption);
                    }}
                />
                {!degreeValid && (
                  <p className="text-red-500 text-xs mt-1 text-right">
                    Please select a degree
                  </p>
                )}
              </div>
            </div>

            <div className="mb-4 md:flex  gap-x-4">
              <div className="w-full  mb-4 md:mb-0">
                <label
                  htmlFor="class_of_degree"
                  className="text-gray-500 mb-4 text-base font-medium"
                >
                  Class Of Degree
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <CustomDropdown
                  options={
                    classOfDegrees?.data?.length ? 
                      classOfDegrees?.data?.map( classOfDegree => {
                        return { label: classOfDegree?.name , value : classOfDegree?.id }
                      } ) : []
                  }
                  value={
                    classOfDegreeId
                      ? { value: classOfDegreeId, label: selectClassOfDegree } // Provide both value and label
                      : null
                  }
                  width={'w-full'}
                  onChange={(selectedOption) => {
                    setClassOfDegreeId(selectedOption?.value || "");
                    setSelectedClassOfDegree(selectedOption?.label || "");
                    setClassOfDegreeValid(!!selectedOption);
                  }}
                />
                {!classofDegreeValid && (
                  <p className="text-red-500 text-xs mt-1 text-right">
                    Please select a degree
                  </p>
                )}
              </div>
            </div>

            <div className="mb-8 md:flex gap-x-4">
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <label
                  htmlFor="start_date"
                  className="text-gray-500 mb-4 text-base font-medium"
                >
                  Start Date
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <input
                  type="Date"
                  id="start_date"
                  className={`rounded-lg  flex-1 appearance-none border ${
                    !startDateValid ? "border-red-500" : "border-lightGrey"
                  }  w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                  name="start_date"
                  placeholder="Enter start date"
                  value={startDate}
                  max={new Date().toISOString().split('T')[0]}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setStartDateValid(true);
                  }}
                />
                {!startDateValid && (
                  <p className="text-red-500 text-xs mt-1 text-right">
                    Please select a degree
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <label
                  htmlFor="end_date"
                  className="text-gray-500 mb-4 text-base font-medium"
                >
                  End Date
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <input
                  type="Date"
                  id="end_date"
                  className={`rounded-lg  flex-1 appearance-none border ${
                    !endDateValid ? "border-red-500" : "border-lightGrey"
                  }  w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                  name="end_date"
                  placeholder="Enter end date"
                  value={endDate}
                  min={startDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setEndDateValid(true);
                  }}
                />
                {!endDateValid && (
                  <p className="text-red-500 text-xs mt-1 text-right">
                    Please select a degree
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-x-10 justify-center">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-primary  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-white text-sm font-medium text-darkBlue  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                onClick={() => closeModal(false)}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-none  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-primary text-sm font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditEducationModal;
