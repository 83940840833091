import React from 'react'
import { AssessementContext } from '../../../../AssessmentContext'
import BooleanQuestions from "./BooleanQuestions";
import InputQuestions from "./InputQuestions";
import RadioSelectQuestions from "./RadioSelectQuestions";

const QuestionTypeWrapper = ({single_assessment, setShowUnusualModal }) => {
         
    const type = (_type) =>  {
        let questionType =  single_assessment?.type.toLowerCase()
       return questionType?.includes(_type)? true : false
    }
        
    if(single_assessment?.type === null){
        return(
            <div className='h-[200px] w-[full] flex items-center justify-center'>
                 <h5 className='text-xl text-red-500 text-center bg-red-100 py-5 px-8 border-l border-red-600'>
                     There is an error with this question.
                 </h5>
            </div>
        )
    }
    
    if(type('select') || type('multiple') || type('all correct')){
       return( <RadioSelectQuestions question = { single_assessment } /> )
    }  
    if( type('matching') || type('fill in the blank')){
        return(
            <InputQuestions 
               question = { single_assessment }
               setShowUnusualModal = { setShowUnusualModal } 
            />  
        )
    }
    
    return <BooleanQuestions  question = { single_assessment } /> 
}

export default QuestionTypeWrapper