import React from 'react'
import logo from "../../images/TestAssessify.png";
import Spinner from '../../components/Spinner';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { api } from '../../api';
import { useQuery } from 'react-query';
import { toast } from 'react-hot-toast';

const Verification = () => {

    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const navigate = useNavigate() 
    const hash = searchParams.get('h');
  
    const { isLoading, isError } = useQuery(["verify otp"],  () => {
      return api.emailVerification({ type: 'link', email, hash })
     }, {
     onSuccess: data => {     
      toast.success('Verification successfull') 
      sessionStorage.setItem("userToken", data.data.token);
      sessionStorage.setItem("user", JSON.stringify(data?.data));
       setTimeout( () => navigate("/candidate/profile"), 300 )
     }
  });

  return (
    <div className="bg-white p-4 lg:p-10">
      <div className="w-full p-3 h-26 bg-inherit sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>
     <section className='mt-12'>
        { isLoading &&
            <div>
                <Spinner text='Verifying...' />
            </div>
        }
        {
              isError &&
              <div style={{flexDirection: 'column'}} className='bg-white w-[300px] mx-auto flex justify-center mt-8 md:mt-[160px]'>
                 <span className='text-7xl text-center text-red-500 bi bi-bug'></span>
                 <h5 className='text-md text-center'>
                     <strong className='text-lg'>Opps!</strong> There was an error with this verification method.
                      you can try OTP method
                 </h5>
                 <Link to={`/email-verification?email=${email}`} 
                   className='text-center mt-3 bg-primary text-white text-sm py-3 px-12 rounded-full'
                  >
                    Verify with OTP
                 </Link>
             </div>}
     </section>
    </div>
  )
}

export default Verification