import React from 'react'
import { useNavigate } from "react-router-dom";
import {
    Chart,
    initTE,
  } from "tw-elements"

const ResultModal = ({setShowResultModal, result}) => {

 const navigate = useNavigate()

 React.useEffect( () => {
    initTE({ Chart });
 }, [])

 const closeModal = () => {
    setShowResultModal(false)
    navigate(-1)
 }

 const { totalCorrect, notAnswered, failed, total } = result
  
  return (    
        <div className='h-[100vh] w-[100vw] bg-[rgba(0,0,0,0.3)]  fixed top-0 left-0' style={{ zIndex:9000000}}>
            <div className='w-full h-full flex items-center justify-center'>
                 <div className='modal-conetent relative bg-white w-[94%] md:w-[53%] h-[500px]  md:h-[540px] xl:h-[550px] rounded-lg'>
                      <h5 className='py-5 px-3 mb-5 shadow-lg text-2xl bi bi-journals'>&nbsp;Candidate Practice Test Result </h5>
                     <div className='w-[96%] mx-auto  mt-4 text-xl'>
                     <div class="mx-auto  md:w-[38%] w-[200px] overflow-hidden mt-12">
                        <canvas
                            data-te-chart="doughnut"
                            data-te-dataset-label="Traffic"
                            data-te-labels="['Unanswerd', 'Failed' , 'Corrrect']"
                            data-te-dataset-data={`[${ notAnswered }, ${failed} ,${totalCorrect}]`}
                            data-te-dataset-background-color="['rgba(63, 81, 181, 0.8)', 'rgba(240, 82,100 , 0.8)', 'rgba(66, 184,60 ,0.7)'  ]">
                        </canvas>
                     </div>
                     <div>
                        <h5 className='text-center text-2xl mt-5'>Score:</h5>
                        <p className='text-center mt-3 text-xl'> Total Questions: &nbsp;{total}</p>
                        <p className='text-center mt-2 text-green-500 text-xl'> Correct: &nbsp;{ totalCorrect }</p>
                        <p className='text-center mt-2 text-xl text-red-500'> Failed: &nbsp;{ failed }</p>
                        <p className='text-center mt-2 text-xl text-blue-500'> Unanswered: &nbsp;{notAnswered}</p>
                     </div>
                     </div>
                     <div className='absolute w-full bottom-0 left-0'>
                        <div className='flex w-full justify-center border-t boredr-t-2 border-gray-200 mt-5 px-3 py-3'>
                                <button  onClick={closeModal}  className='bg-darkBlue py-3 px-16 rounded-lg text-white text-lg'>
                                    View user group assessment
                                </button>
                        </div>
                     </div>
                 </div>
            </div>
        </div>     
  )
}

export default ResultModal