import React, { useState } from "react";
import { useQuery } from "react-query";
import { api } from "../../../../../../api";
import toast from "react-hot-toast";
import ScaleLoader_ from "../../../components/ScaleLoader";
import { useNavigate } from "react-router-dom";

function unlockAssessmentModal({ closeModal, getExamsMutation, id, refetch }) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    otp: "",
    venue_code: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleUnlockAssessment = async (e) => {
    e.preventDefault();
    let payload = {
      otp: values.otp,
      user_group_id: sessionStorage.getItem("user_group_id"),
      venue_code: parseInt(values.venue_code),
    };

    try {
      toast.loading("loading...");
      const res = await api.unlockAssessment(payload);
      toast.remove();
      // getExamsMutation.mutate(id);
      toast.success(res.message);
      window.location.href =
        "https://candidate.speedexam.net/signin.aspx?site=setassessment";
      refetch();
      closeModal(false);
    } catch (error) {
      toast.remove();

      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen grid place-items-center bg-black bg-opacity-50 p-10 ">
      <div className="">
        <div className="min-h-[12rem] md:w-[600px] bg-white rounded-[32px] flex flex-col justify-center p-6 md:p-16 relative">
          <h3 className="flex justify-start text-lg leading-6 font-medium text-gray-900 mb-4">
            Unlock Assessment
          </h3>
          {/* {getExamsMutation.isLoading && <ScaleLoader_ />} */}
          <form onSubmit={handleUnlockAssessment}>
            <div className="mb-4">
              <label
                htmlFor="otp"
                className="flex justify-start mb-5 text-lightGrey text-base"
              >
                OTP
              </label>
              <input
                value={values.otp}
                onChange={handleInputChange}
                type="text"
                id="otp"
                className=" rounded-lg border-transparent flex-1 appearance-none border border-lightGrey w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent"
                name="otp"
                placeholder="One Time Password"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="venue_code"
                className="flex justify-start mb-5 text-lightGrey text-base"
              >
                Venue Code
              </label>
              <input
                value={values.venue_code}
                onChange={handleInputChange}
                type="text"
                id="venue_code"
                className=" rounded-lg border-transparent flex-1 appearance-none border border-lightGrey w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent"
                name="venue_code"
                placeholder="Venue Code"
              />
            </div>

            <div className="flex  justify-center gap-x-10">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-darkBlue  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-white text-sm font-medium text-darkBlue  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                onClick={() => closeModal(false)}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-darkBlue  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-darkBlue text-sm font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default unlockAssessmentModal;
