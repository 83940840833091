import React from "react";
import Summary from "./components/Summary";
import Table from "./components/Table";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { api } from "./../../../api/index";
import ScaleLoader_ from "./../assessments/components/ScaleLoader";


const ExamHistory = () => {

  const [examHistory, setExamHistory] = React.useState([]);  

  const { data: examData, isLoading, isError } = useQuery(
    ["get exam history"],
    async () => {
      const data = await api.getExamsHistory();
      return data.data;
    },
    {
      onSuccess: data => {
        setExamHistory(data?.exams?.data);        
      }
    },
    { cacheTime: 30000, staleTime: 30000, refetchOnWindowFocus: true },
  );

 if(isLoading) return <ScaleLoader_ />  

  return (
    <React.Fragment> 
       <div className="mt-1 md:mt-0">
        <Link to={'/candidate/exam-history'} className='text-md'>
              Result History
          </Link> 
        <span className='mx-2'>/</span>
          <Link to={'/candidate/exam-history'} className='text-md text-customGreen'>
              Summary
          </Link>  
       </div> 
      <Summary summary={ examData?.summary } />
      <Table examHistory={examHistory} />
    </React.Fragment>
  );
};

export default ExamHistory;
