import React, { useState, } from "react";
import { useQuery } from 'react-query';
import { api } from '../../../../api';
import { ScaleLoader } from "react-spinners";

const ViewResultModal = ({ setOpenModal, activeResultId }) => {

    const {
        isLoading,
        data: categoryResult,
      } = useQuery(
        "user-mock-exam-details",
        async () => {
          const data = await api.getMockExamDetails({
            groupId: sessionStorage.getItem('group_id'),
            userGroupId: sessionStorage.getItem('user_group_id'),
            id: activeResultId,
          });
          return data;
        }, {
            select: response => {
                return response?.data
            }
        }
      );

    let resultIDS = [
    { id: 1, title: "Questions answered", color: "black" },
    { id: 2, title: "Correct answers", color: "primary" },
    { id: 3, title: "Incorrect answers", color: "customOrange" },
    { id: 4, title: "Unanswered questions", color: "slate-400" },
    ];
    
    let percentageValues = ["0","10","20","30","40","50","60","70","80","90","100",];
   
  return (
    <div className="fixed top-0 left-0 w-screen h-screen grid place-items-center bg-black bg-opacity-50 p-5 ">
      <div className="">  
        <div className="min-h-[12rem] -ml-2 w-[96vw] overflow-x-hidden md:w-[800px] xl:w-[880px] xl:ml-[200px] bg-white md:ml-[180px] rounded-[32px] flex flex-col justify-center p-6 md:p-16 relative">
           <header className="flex justify-between shadow-md mb-3 px-2 pb-3">
            <h3 className="text-xl leading-6 font-medium text-gray-900 mb-4">
                My Result 
            </h3>
            <button className="bi bi-x-lg text-xl" 
               onClick={ () => setOpenModal(false) }
            />
           </header>
           <section>
               {
                 isLoading ? <div className="flex justify-center my-8">
                     <ScaleLoader color="#87aa4b" />
                 </div> :
                 (
                    <div className="w-full mt-3">
                      <p className="text-slate-500 mb-3 text-baseFont">
                          Status: 
                          {
                            categoryResult?.status === 'fail' ?
                            <span className='ml-5 text-red-500 bg-red-100 py-1 px-8'>
                            {categoryResult?.status}
                            </span> :
                            <span className='ml-5 text-primary bg-green-100 py-1 px-8'>
                            {categoryResult?.status}
                           </span>
                        }
                      </p>
                      <p className="text-slate-500 mb-3 text-baseFont">
                        Pass Mark:{" "}
                        <b className="font-medium text-slate-500">
                          {categoryResult?.pass_mark}
                        </b>
                      </p>
                      <p className="text-md  border-b border-gray-300 pb-3 text-slate-500 mb-2">
                         Category : &nbsp; {categoryResult?.category_title}
                      </p>
                      <ul className="md:flex items-center justify-start gap-3 mt-3">
                        {resultIDS?.map((identifier) => (
                          <li
                            className="flex items-center justify-start gap-1"
                            key={identifier.id}
                          >
                            <p className="text-md">{identifier.title}: </p>
                            <div
                              className={`w-2 h-2 bg-${identifier.color} border border-${identifier.color} rounded-full`}
                            ></div>
                          </li>
                        ))}
                      </ul>
                      <div className="w-full my-4">
                        {categoryResult?.info?.map((infoData) => (
                          <div
                            className="w-full mb-2 md:flex items-center justify-start gap-1"
                            key={infoData.id}
                          >
                            <p className="text-baseFont" style={{ width: "150px" }}>
                              {infoData?.section?.title}
                            </p>
                            {/* <span
                              className={
                                infoData?.percentage > 50
                                  ? "border border-paleGreen bg-paleGreen text-customGreen px-5 py-1 text-md rounded-2xl"
                                  : "border border-paleOrange bg-paleOrange text-customOrange px-5 py-1 text-md rounded-2xl"
                              }
                            >
                              {infoData?.percentage > 50 ? "Pass" : "Fail"}
                            </span> */}
                            <div className="flex-1 ml-1">
                              <div
                                className="w-full relative border border-slate-300 mb-1 py-1 rounded-xl overflow-hidden"
                                style={{ boxShadow: "0 0 1px 1px #ccc inset" }}
                              >
                                <div
                                  className={
                                    infoData?.percentage > 50
                                      ? "bg-customGreen top-0 left-0 absolute rounded-xl"
                                      : "bg-customOrange top-0 left-0 absolute rounded-xl"
                                  }
                                  style={{
                                    width: `${infoData?.percentage}%`,
                                    height: "100%",
                                  }}
                                ></div>
                              </div>
                              <div className="w-full flex items-center justify-between">
                                {percentageValues?.map((value) => (
                                  <p className="text-md text-slate-400">{value}</p>
                                ))}
                              </div>
                            </div>
                            <div
                              className={
                                infoData?.percentage > 50
                                  ? "text-customGreen text-baseFont w-[30px] mx-5"
                                  : "text-customOrange text-baseFont w-[30px] mx-5"
                              }
                            >
                              {infoData?.percentage}%
                            </div>
                            <div className="flex items-center justify-between gap-4 w-[120px]">
                              <div className="flex items-center justify-start gap-1">
                                <div
                                  className={`w-2 h-2 bg-black border border-black rounded-full`}
                                ></div>
                                <p className="text-baseFont">
                                  {infoData?.question_count}
                                </p>
                              </div>
                              <div className="flex items-center justify-start gap-1">
                                <div
                                  className={`w-2 h-2 bg-customGreen border border-customGreen rounded-full`}
                                ></div>
                                <p className="text-baseFont text-customGreen">
                                  {infoData?.correct_count}
                                </p>
                              </div>
                              <div className="flex items-center justify-start gap-1">
                                <div
                                  className={`w-2 h-2 bg-customOrange border border-customOrange rounded-full`}
                                ></div>
                                <p className="text-baseFont text-customOrange">
                                  {infoData?.incorrect_count}
                                </p>
                              </div>
                              <div className="flex items-center justify-start gap-1">
                                <div
                                  className={`w-2 h-2 bg-slate-400 border border-slate-400 rounded-full`}
                                ></div>
                                <p className="text-baseFont text-slate-400">
                                  {infoData?.question_count -
                                    infoData?.correct_count -
                                    infoData?.incorrect_count}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
               }
           </section>
        </div>
      </div>
    </div>
  );
}

export default ViewResultModal;
