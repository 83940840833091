import React from 'react'
import { AssessementContext } from '../../../../AssessmentContext'


const BooleanQuestions = ({question}) => {
  
  const { assessment, setAssessment } = React.useContext( AssessementContext )

  const handleSaveAnswer = (event, answer) => {
    setAssessment(prevAssessment => {
      return { 
                ...prevAssessment, 
                assessements: {...prevAssessment?.assessements,
                  questions: [
                     ...prevAssessment?.assessements?.questions?.map( question_ => {
                      if(question?.id === question_?.id){
                          return {
                            ...question_, answers : [...question_?.answers?.map( ans => {
                              if(answer?.id === ans?.id ){
                                return {...ans, text: event.target.value , status: true }
                              }
                              else return {...ans}
                            })]
                        }
                      }
                      else return {...question_}  
                     })
                  ]
                }
          }
     }) 
  }

  return (
    <div >  
        <form className={`form-${question.id} flex  px-3`}>
             <div className='flex items-center w-[50%] md:w-[35%] pr-5 ml-5'>
                   <label htmlFor="Agree" className='text-lg mr-2'> 
                     {assessment?.activeQuestion?.type === 'true or false'?
                        'True': 'Yes'
                     } 
                   </label>
                   <input 
                       type="radio" name="answer" id="Agree"  
                       className='w-[20px] h-[20px] green-radio' 
                       value={'Yes'}       
                       defaultChecked = { 
                         question?.answers[0]?.status === true ? true : false                                         
                        }               
                       onClick={ (event) => handleSaveAnswer(event, question?.answers[0] ) }
                    />
             </div>
             <div className='flex items-center w-[50%]'>
                   <label htmlFor="Disagree" className='text-lg mr-2'> 
                     {assessment?.activeQuestion?.type === 'true or false'?
                        'False': 'No'
                     } 
                   </label>
                   <input 
                       type="radio" name="answer" id="Disagree" 
                       value={'No'} 
                       defaultChecked = { 
                          question?.answers[1]?.status === true ? true : false                                       
                        }
                       onClick={ (event) => handleSaveAnswer(event, question?.answers[1] )}
                       className='w-[20px] h-[20px] green-radio'
                    />
             </div>
        </form>
    </div>
  )
}

export default  React.memo(BooleanQuestions)