import React from "react";
import success from "./success.png";
import { useNavigate, Link } from "react-router-dom";

const Redirect = ({ handleRedirect, title, showCancelBtn, btnTitle, to  }) => {
  //   console.log("hi");
  const navigate = useNavigate();
  return (
    <div className="fixed z-[1000000000000] bg-black flex items-center p-5 justify-center bg-opacity-70 top-0 left-0 h-[100vh] w-[100vw]">
      <div className="flex justify-center flex-col items-center gap-4 max-w-[400px] w-full max-h-[500px] bg-white rounded-md p-5">
        <img src={success} alt="success" className="w-20" />
        <h1 className="text-xl text-center text-gray-700">
          {
             title || 'You have a pending mock exam'
          }
          </h1>
         <div className="flex justify-between">
         { showCancelBtn ? <button
              onClick={() => {
                handleRedirect();
              }}
              className="px-6 py-3 rounded-md text-white bg-red-500 text-md"
            >
              Cancel
        </button> : null }
            <button
              //   to={"/candidate/assessments/view-assessments"}
              onClick={() => {
                //   console.log("ww");
                navigate( to ||"/candidate/assessments/view-assessments");
                handleRedirect();
              }}
              className="px-6 py-3 rounded-md ml-8 text-white bg-primary text-md"
            >
               {btnTitle || 'Click to View'}
        </button>
         </div>
      </div>
    </div>
  );
};

export default Redirect;
