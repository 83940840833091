import React from 'react'
import { useMutation } from "react-query";
import { api } from "./../../../../../../api/index";
import ScaleLoader from "./../../../components/ScaleLoader";
import { AssessementContext } from '../../../AssessmentContext';
import { toast } from "react-hot-toast";

const unsupportedActions = [
    'Minimizing the browser',
    'Resizing the browser',
    'Open a new tab',
    'Open a new program',     
    'Pressing Ctrl + C',
    'Pressing Ctrl + V',
    'Pressing Print Screen',
    'Pressing F12',
]

const UnsuspiciousActivityModal = ({SetShowUnusualModal}) => {

  const { assessment, setAssessment } = React.useContext( AssessementContext )

  const flagAssessmentMutation = useMutation(api.flagAssessment,{
     onSuccess: (data) => {
         if(data?.termination){
            setAssessment({...assessment, isTerminated:true})
         }
     },
     onError: (data) => {
         toast.error(`Error! an unknown error ocuured please try again.`)
     }
  })

  const takeActionOnUnusualActivity = () => {
      SetShowUnusualModal(false)
      if(process.env.NODE_ENV === 'production'){
        //enforce full screen
        const element = document.documentElement; // Get the root element of the document
        if (element.requestFullscreen) {
        element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen();
        }
        //send candidate activity to the backend
       /* flagAssessmentMutation.mutate({
          user_group_id:  JSON.parse(
               window.sessionStorage.getItem('submit-assessment-payload')
               ).user_group_id,
          reason: "candidate activity was suspicious.",
          assessment_id: JSON.parse(
            window.sessionStorage.getItem('submit-assessment-payload')
            ).assessment_id
       })*/
      }    
  }

  return (

    <div className='h-[100vh] w-[100vw] bg-[rgba(0,0,0,0.3)] fixed top-0 left-0' style={{ zIndex:9000000}}>
         { flagAssessmentMutation.isLoading && <ScaleLoader /> }         
        <div className='w-full h-full -mt-8 md:mt-0 flex items-center p-0 m-0 justify-center'>
             <div className='bg-white w-[98%] md:w-[40%] h-[510px] rounded-lg'>
             <div
          class="shadow-lg flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">           
          <h5
            class="text-2xl font-medium leading-normal text-gray-900"
            id="exampleModalLabel">
              Unusual Activity               
              <span className='block text-md md:text-lg font-normal'>
                 To ensure uninterrupted exam delivery. kindly check your sustem compatibility
                 and proximity to our exam server.
              </span>
          </h5>                     
        </div>         
        <div class="relative flex-auto p-4" data-te-modal-body-ref>
            <p className='text-md md:text-xl text-red-500 mt-4 mb-5'>
                Your last activity was considered suspicious. Below are the actions considered
                suspicious.
            </p>
            <ul>
            {
                    unsupportedActions.map( (unsupportedAction, id) => {
                        return(
                            <li key={id} className='mt-3 text-md md:text-lg capitalize flex items-center'>
                                    <span className='text-md md:text-xl text-red-500 bi bi-exclamation-triangle ml-3 mr-2'></span>
                                    {unsupportedAction}
                            </li>
                        )
                    } )
                }
            </ul>
            <p className='mt-3 md:mt-5 text-lg'>
                 Doing any of the actions up to 5 times will result in your assessment getting terminated.                  
            </p>
        </div>        
        <div
          class="flex flex-shrink-0 flex-wrap items-center bg-red-100 justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
          <button
            type="button"
            class="inline-block rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out focus:outline-none focus:ring-0 a"
             onClick={ takeActionOnUnusualActivity }          
            >
            Ok, i understand
          </button>           
        </div>
             </div>
        </div>         
    </div>
  )
}
           
export default UnsuspiciousActivityModal