import React from 'react'

const RadioSelectQuestions = ({question,testAssessment, setTestAssessment}) => {
   
  const options = ['A','B','C','D','E']

  const handleSavedAnswer = (event,answer) => {
    const savedAnswer = (answers, _updatedAnswer) => {
      
      const updatedAnswer = {
        answer_id : _updatedAnswer?.id ,
        question_id: _updatedAnswer?.question_id,
        is_correct: _updatedAnswer?.is_correct,
        answer_text: event.target.value,                         
        category_id: question?.category_id,
        section_id: question?.section_id,
      }

      const index = answers.findIndex(obj =>  {
         if(question?.type !== 'select') {            
            return (obj.question_id === updatedAnswer.question_id) &&  (obj.answer_id === updatedAnswer.answer_id)
         }
          else return (obj.question_id === updatedAnswer.question_id)
      });

      if (index >= 0) {
        if(question?.type === 'select'){
          const updatedAssessment = [...answers];
          updatedAssessment[index] = updatedAnswer;          
          return updatedAssessment;
        }
        else{           
           return [...answers].filter( (x, id) => id !== index ) 
        }
        
      } else {
        return [...answers, updatedAnswer];
      }
  
    }

    setTestAssessment(prevAssessment => {
      return {...prevAssessment, 
       savedAnswers : 
        [...savedAnswer(prevAssessment.savedAnswers, answer)]}
    })     
}

const isSelectedAnswer = (answer) => {

  const updatedAnswer = {
    answer_id : answer?.id ,
    question_id: answer?.question_id,     
  }

  const index = testAssessment.savedAnswers.findIndex(obj =>  {             
       return (obj.question_id === updatedAnswer.question_id) &&  (obj.answer_id === updatedAnswer.answer_id)     
 });

 if (index >= 0) return true  
 else return false;
}

  return (
    <div>   
      <form className={`form-${question.id}`}>
        <div className='flex  flex-wrap'>  
              {
                 testAssessment.activeQuestion.type.toLowerCase() === 'select' ?                    
                       testAssessment.activeQuestion?.answers.map( (answer, id) => {
                           return(                             
                            <div key={id} className='flex items-center w-[90%] md:w-[35%] pr-5 ml-5 pr-2 mb-8'>               
                                 <input 
                                     type="radio" name="select"
                                      className='w-[16px] h-[16px] mr-2'
                                      value={answer?.text}
                                      defaultChecked = { 
                                          isSelectedAnswer(answer) ? true : false                                         
                                       }
                                      onClick={ (event) => handleSavedAnswer(event,answer) }
                                  /> 
                                 <span className='text-lg flex items-center'> {options[id]}. &nbsp; 
                                     <span className='answer-wrapper text-xl' dangerouslySetInnerHTML={{__html: answer.text}} />
                                 </span>
                             </div>                                                          
                           )
                       } )                    
                    :
                    testAssessment.activeQuestion?.answers.map( (answer, id) => {
                      return(                             
                       <div key={id} className='flex items-center w-[90%] md:w-[35%] pr-5 ml-5 mb-8'>               
                            <input 
                                 type="checkbox" name={id} 
                                 className='w-[16px] h-[16px] mr-2'
                                 value={answer?.text}
                                 defaultChecked = { 
                                  isSelectedAnswer(answer) ? true : false                                         
                                  }
                                 onChange={ (event) => handleSavedAnswer(event,answer) }
                            />
                            <span className='text-lg flex'> {options[id]}. &nbsp; 
                                <span className='answer-wrapper text-xl' dangerouslySetInnerHTML={{__html: answer.text}} />
                            </span>
                        </div>                                                          
                      )
                  } )                     
              }
        </div>        
      </form>
    </div>
  )
}

export default RadioSelectQuestions