import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { useForm, FormProvider, useFormContext, useController } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useQuery } from "react-query";
import logo from "../../images/TestAssessify.png";
import { api } from "../../api";
import PhoneInput from "react-phone-number-input";
import SuccessfulReg from "./successfulReg";
import Input from "./../../components/Global/Input";
import { useCustomToast } from "../../components/customtoast/CustomToastProvider";
import "react-phone-number-input/style.css"; // Make sure to import the styles

function PhoneInputField({ name, control, rules }) {
  const { field } = useController({ name, control, rules });
  return (
    <PhoneInput
      {...field}
      className={`rounded-lg flex-1 appearance-none border ${
        field.value ? "border-lightGrey" : "border-red-500"
      } w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
      international={true}
      defaultCountry="NG"
      placeholder="Your phone number"
    />
  );
}

function Register({ closeModal }) {
  const ref = useRef();
  const customToast = useCustomToast();
  const methods = useForm();
  const { watch, control, handleSubmit, formState: { errors } } = methods;
  const location = useLocation();
  const navigate = useNavigate();
  const previousRoute = location.state?.from;
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);

  // Extract the slug and referral from url
  const slug = location.pathname.split("/")[2];
  const referral = new URLSearchParams(location.search).get("referral");

  sessionStorage.setItem("slug", slug || null);
  sessionStorage.setItem("referral", referral || null);

  let payload = {
    slug: location.pathname.split("/")[2],
    referral: new URLSearchParams(location.search).get("referral"),
  };

  if (slug && referral !== "null") {
    const { isLoading, data, isError, error } = useQuery(
      ["verify-campaign-data"],
      async () => {
        const data = await api.verifyCampaignData(payload);
        return data;
      }
    );
  }

  const resetForm = () => {
    methods.reset();
    methods.setValue("email", ""); // Reset email field
    methods.setValue("first_name", ""); // Reset first name field
    methods.setValue("last_name", ""); // Reset last name field
    methods.setValue("password", ""); // Reset password field
    methods.setValue("password_confirmation", ""); // Reset confirm password field
    methods.setValue("phone_number", ""); // Reset phone number field
  };

  const onSubmit = async (data) => {
    sessionStorage.setItem('verification-email', data?.email)
    try {
      toast.loading("Loading...");
      const res = await api.signUp({
        ...data,
        slug: payload.slug,
        referral: payload.referral,
        base_url: window.location.origin,
      });
      toast.remove();
      sessionStorage.setItem("userToken", res.data.token);
      sessionStorage.setItem("user", JSON.stringify(res?.data));
      customToast.success(`Registration successful.`);
      navigate("/candidate/profile");
      resetForm();
    } catch (error) {
      toast.remove();
      customToast.error(error?.response?.data?.message);
    }
  };

  const handleNavigateToLogin = () => {
    navigate("/", {
      state: { from: ["/application/:slug", "/application/:slug/:referral"] },
    });
  };

  const validatePhoneNumber = (value) => {
    const phoneNumberLength = value.replace(/\D/g, '').length; // Remove non-digit characters and get length
    const countryCode = value.startsWith("+234") ? "NG" : "Other"; // Check if the number is Nigerian

    if (countryCode === "NG" && phoneNumberLength < 11) {
      return "Nigerian phone number must be at least 11 digits long";
    } else if (countryCode !== "NG" && phoneNumberLength < 6) {
      return "Phone number must be at least 5 digits long";
    }
    return true;
  };

  return (
    <div className="bg-white p-4 lg:p-6">
      <div className="w-full pb-3 h-26 bg-inherit sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>

      <div className="flex justify-center items-center mt-20 2xl:mt-0 2xl:h-screen">
        <div className="bg-cover bg-no-repeat w-full bg-hero-img md:w-[600px] lg:w-[900px] 2xl:w-[1440px] rounded-xl p-2 lg:p-10 sm:shadow-2xl">
          <div className="my-2 shadow-2xl rounded-[8px] bg-white w-full lg:w-1/2 2xl:mt-20 p-4 2xl:p-6">
            <div className="flex flex-col w-full">
              <div className="flex flex-col justify-center px-4 pt-8 my-auto md:justify-start md:pt-0 lg:px-4">
                <div className="2xl:mb-10">
                  <p className="text-3xl text-darkBlue pt-4 mb-6 font-semibold text-center">
                    Welcome to Test Assesify
                  </p>
                  <p className="text-2xl font-medium text-darkBlue mb-5">
                    How To Apply For Jobs On Test Assessify
                  </p>
                  <div>
                    <p className="mb-4">
                      <span className="font-medium text-darkBlue text-xl">
                        Step 1:
                      </span>
                      Create a profile or log in to your existing account
                    </p>
                    <p className="mb-4">
                      <span className="font-medium text-darkBlue text-xl">
                        Step 2:
                      </span>
                      Apply for a role and submit your application.
                    </p>
                    <p className="mb-4">
                      <span className="font-medium text-darkBlue text-xl">
                        Step 3:
                      </span>
                      Get notified and track your progress.
                    </p>
                  </div>
                  <p className="text-2xl font-medium text-darkBlue mt-4">
                    Create Account
                  </p>
                </div>
                <FormProvider {...methods}>
                  <form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pt-4 mb-4">
                      <div className="flex relative 2xl:mb-10 group">
                        <span className="inline-flex rounded-l-lg items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                          <svg
                            width="15"
                            height="15"
                            fill="currentColor"
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                          </svg>
                        </span>
                        <Input
                          name="email"
                          placeholder="Enter your email"
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          }}
                          type="email"
                          ref={(ref) => methods.register(ref)}
                        />
                      </div>
                    </div>
                    <div className="mb-4 2xl:mb-10">
                      <Input
                        name="first_name"
                        placeholder="Enter your first name"
                        rules={{ required: "First Name is required" }}
                        type="text"
                        ref={(ref) => methods.register(ref)}
                      />
                    </div>
                    <div className="mb-6 2xl:mb-10">
                      <Input
                        name="last_name"
                        placeholder="Enter your last name"
                        rules={{ required: "Last Name is required" }}
                        type="text"
                        ref={(ref) => methods.register(ref)}
                      />
                    </div>
                    <div className="mb-6 2xl:mb-10">
                      <PhoneInputField
                        name="phone_number"
                        control={control}
                        rules={{ required: "Phone number is required", validate: validatePhoneNumber }}
                      />
                      {errors.phone_number && (
                        <p className="text-red-500 mt-1">{errors.phone_number.message}</p>
                      )}
                    </div>
                    <div className="mb-6 password-wrapper 2xl:mb-10">
                      <Input
                        name="password"
                        placeholder="Enter password"
                        rules={{
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message: "Password must be at least 8 characters long",
                          },
                          pattern: {
                            value: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]{8,}$/,
                            message: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                          },
                        }}
                        type="password"
                        ref={(ref) => methods.register(ref)}
                      />
                    </div>
                    <ul className='my-3 bg-white p-2 w-[90%] rounded-lg'>
                      <li className={`${ 
                                         `${watch('password')}`.length < 8 ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp; Password must be at least 8 characters long.
                      </li>
                      <li className={`${ 
                                        !/[A-Z]/.test(watch('password')) ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp; Password must contain at least one uppercase letter.
                      </li>
                      <li className={`${ 
                                        !/[a-z]/.test(watch('password')) ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp;  Password must contain at least one lowercase letter.
                      </li>
                      <li className={`${ 
                                      !/\d/.test(watch('password')) ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp;  Password must contain at least one number.
                      </li>
                      <li className={`${ 
                                        !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/.test(watch('password')) ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp; Password must contain at least one special character.
                      </li>
                    </ul>
                    <div className="mb-6 2xl:mb-10">
                      <Input
                        name="password_confirmation"
                        placeholder="Confirm password"
                        rules={{
                          required: "Password confirmation is required",
                          validate: (value) =>
                            value === watch("password") || "Passwords do not match",
                        }}
                        type="password"
                        ref={(ref) => methods.register(ref)}
                      />
                    </div>
                    <div className="sm:flex justify-between items-center">
                      <button
                        type="submit"
                        className="w-full sm:w-fit text-center px-6 py-4 rounded-lg text-base font-semibold text-white transition duration-200 ease-in bg-[#88a848] shadow-md active:border-none active:outline-none focus:border-none focus:outline-none"
                      >
                        <span className="text-xl 2xl:text-2xl">Sign Up</span>
                      </button>
                      <div className="py-4 text-center 2xl:py-8">
                        <p className="text-xl">
                          Already have an account? &nbsp;
                          <button
                            type="button"
                            onClick={handleNavigateToLogin}
                            className="font-semibold underline text-primary"
                          >
                            Login
                          </button>
                        </p>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
