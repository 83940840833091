import React from 'react'
import { AssessementContext } from '../../../AssessmentContext';
import { useNavigate } from 'react-router-dom'
import { defaultState } from '../../../components/defaultState';

const AssessmentTerminationModal = () => {

 const  { assessment, setAssessment } = React.useContext( AssessementContext )
 const navigate = useNavigate() 

 const closeModal = () => {
    setAssessment(defaultState)     
    navigate('/candidate/exam-history') 
 }

  return (
    <div className='h-[100vh] w-[100vw] bg-[rgba(0,0,0,0.3)] fixed top-0 left-0' style={{ zIndex:9000000}}>
        <div className='w-full h-full flex items-center justify-center'>           
             <div className='modal-conetent bg-white w-[94%] md:w-[35%] h-[360px] rounded-lg'>
                  <h5 className='py-5 px-3 mb-5 shadow-lg text-xl'> Assessment Terminated </h5>
                 <div className='w-[80%] mx-auto h-[210px] mt-4 calc'>
                     <p className='flex justify-center'>
                        <span className='text-[100px] bi bi-x-octagon text-red-500'>
                        </span>
                     </p>
                     <p className='text-xl text-center mt-3'>
                        You had reached the end of your unusual activity warnings,
                        so your assessment is now terminated.
                     </p>
                 </div>
                <div className='flex justify-center border-t boredr-t-2 border-gray-200 mt-5 px-3 py-3'>
                         <button onClick={closeModal}  className='bg-red-500 py-3 px-16 rounded-lg text-white text-lg'>
                              View Assessment History
                        </button>
                </div>
             </div>
        </div>
    </div>
  )
}

export default AssessmentTerminationModal