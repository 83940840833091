import React, { useState } from "react";
import Logo from "../../images/TestAssessify.png";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import toast from "react-hot-toast";
import { api } from "../../api";

function RejoinTestPage() {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true);

  const [values, setValues] = useState({
    email: "",
    resumption_code: "",
  });

  const validateEmail = (email) => {
    const emailRegex =
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}(?:\.[A-Z]{1,})?$/i;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    if (values.email) {
      setIsValid(validateEmail(values.email));
    }
  };

  const reLogin = async (e) => {
    e.preventDefault();
    if (!values.email || !values.resumption_code) {
      toast.error("All field is required");
      return;
    }
    try {
      toast.loading("Loading...");
      const res = await api.loginExam(values);
      toast.remove();
      sessionStorage.setItem("userToken", res.data.token);
      sessionStorage.setItem("user", JSON.stringify(res?.data));
      toast.success("Welcome back, Please continue your assessment");
      if (res.success === true) {
        navigate("/candidate/assessments/candidate-exams", {
          state: { from: "/rejoin-exam" },
        });
      }
      setValues({
        email: "",
        resumption_code: "",
      });
    } catch (error) {
      toast.remove();
      toast.error(error?.response.data.error);
    }
  };
  return (
    <div className="bg-[#f4ede5] min-h-screen p-4">
      <div className="grid place-content-center">
        <div className="md:w-[700px]  bg-white shadow-2xl rounded-lg my-20 md:p-14">
          <div className="flex justify-center mb-16">
            <img src={Logo} alt="testassessify-logo" className="w-fit h-14" />
          </div>
          <div className="flex justify-center">
            <div className="my-10 text-center">
              <h3 className="text-2xl font-normal pt-4">
                TestAssessify Assessments
              </h3>
              <h5 className="font-medium text-lg">Start your test now</h5>
              <p className="pt-8 font-normal text-lg">Test Link Based Exam</p>
            </div>
          </div>

          <div className="">
            <form onSubmit={reLogin}>
              <div className="relative mb-6 ">
                <label
                  htmlFor="email"
                  className="text-gray-500 mb-6 text-lg font-medium"
                >
                  Email
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <div className="flex">
                  <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                    <svg
                      width="15"
                      height="15"
                      fill="currentColor"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="email"
                    className=" rounded-r-lg flex-1 appearance-none border border-gray-500 w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    placeholder="Enter your email"
                  />
                </div>
                {!isValid && (
                  <p className="text-center text-red-500 p-2">
                    Please enter a valid email address.
                  </p>
                )}
              </div>
              <div className="relative">
                <label
                  forHtml="resumption_code"
                  className="text-gray-500 mb-10 text-lg font-medium"
                >
                  Rejoin Code
                  <span className="text-red-500 required-dot">*</span>
                </label>

                <input
                  type="text"
                  id="resumption_code"
                  className=" rounded-lg flex-1 appearance-none border border-gray-500 w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  name="resumption_code"
                  placeholder="Your code"
                  value={values.resumption_code}
                  onChange={handleInputChange}
                />
              </div>
              <button
                type="submit"
                className="py-4 px-4 mt-10 bg-primary hover:bg-primary focus:ring-primary focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 !text-center text-xl font-semibold shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
              >
                Rejoin Test
              </button>
            </form>
            <div
              className="flex gap-x-2  justify-center items-center mt-6 underline text-primary"
              onClick={() => navigate(-1)}
            >
              <AiOutlineArrowLeft />
              <button className=" text-lg">Go back</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RejoinTestPage;
