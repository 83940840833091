import React, { useContext } from 'react'
import { Modal, Ripple, initTE } from "tw-elements";
import { AssessementContext } from "./../../../AssessmentContext";
import PreviewOptionWrapper from "./PreviewOptionWrapper";
 
const PreviewExamModal = ({ hours, minutes , seconds, submitAssessment }) => {

    const { assessment, } = useContext( AssessementContext )

    React.useEffect(() => {
        initTE({ Modal, Ripple });                
    }, [])

  return (
    <>       
    <button
    type="button"
    className="p-0 m-0 w-0 h-0"
    data-te-toggle="modal"
    id='preview-modal-fullscreen'
    data-te-target="#previewModalFullscreen"
    data-te-ripple-init
    data-te-ripple-color="light">        
    </button>

    <div
    data-te-modal-init
    className="fixed left-0 top-0 z-[1055]  bg-white hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
    id="previewModalFullscreen"
    tabindex="-1"     
    aria-labelledby="exampleModalFullscreenLabel"
    aria-hidden="true">
    <div
     data-te-modal-dialog-ref      
    className="pointer-events-none bg-white relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[0px]:m-0 min-[0px]:h-full min-[0px]:max-w-none">
    <div
      style={{ backgroundColor: '#fff'}}
      className="pointer-events-auto relative flex w-full flex-col rounded-md bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600 min-[0px]:h-full min-[0px]:rounded-none min-[0px]:border-0">
      <div
        style={{ backgroundColor: '#fff'}}
        className="flex shadow-lg flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50 min-[0px]:rounded-none">        
        <h5
          className="md:flex md:justify-between bg-white font-medium leading-normal text-gray-900"
          id="exampleModalFullscreenLabel">
            <span className='text-xl md:text-2xl block'>
                 <i className='bi bi-eye-slash mr-2 text-2xl'></i>
                 Preview Question & Answers
                 <br />
                 <i className='text-lg text-red-500'>
                    Please note that your exam will be automatically 
                    submitted once you are timed out.
                 </i>
            </span>
            <span className='mt-5 md:mt-0 block py-3 md:py-0 flex items-center md:ml-[100px] xl:ml-[200px] px-4 border border-red-500 bg-red-100 text-red-500'>
                <i className='bi bi-clock-history mr-1 text-red-500'></i> Time Left: &nbsp;
                {hours < 10 ? `0${hours}` : hours }: {minutes < 10 ? `0${minutes}` : minutes }: {seconds < 10 ? `0${seconds}` : seconds } 
            </span>
        </h5>        
        <button
          type="button"
          className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss
          aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      
      <div className="relative p-4 min-[0px]:overflow-y-auto" id='preview-question-container'>
        <div className='px-3 md:px-8 py-3'>
            {
                assessment.assessements?.questions?.map( (single_assessment,index) => {                     
                       return <div className='py-5 mb-3 border-b'>
                          <h5 className='text-xl mb-3 bg-gray-900 text-white py-4 px-3 md:flex justify-between'>                             
                            <span className='text-xl'>
                              Question  &nbsp; ({index + 1 })
                            </span>
                            <span className='text-xl block mt-3 md:mt-0'>
                              Section: &nbsp; {single_assessment?.section?.title}
                            </span>
                          </h5>
                          <p 
                               className='text-[15px]' 
                               dangerouslySetInnerHTML={{ __html : (single_assessment['text']) }} 
                         />
                          <h5 className='text-xl mt-12 mb-3'> Options </h5>                      
                          <PreviewOptionWrapper
                             answers = { single_assessment.answers }
                             type = { single_assessment.type }
                          />
                      </div>
                })
            }
        </div>
      </div>     
            <div
                className="mt-auto flex flex-shrink-0 justify-between items-center md:justify-end rounded-b-md border-t-2 border-gray-200 border-opacity-100 p-4 dark:border-opacity-50 min-[0px]:rounded-none">
                
                <button
                   type='button'
                   className='text-lg text-gray-900  bi bi-arrow-left bg-gray-300 px-12 md:px-16 mr-5 py-3 rounded-lg'
                   data-te-modal-dismiss
                 >
                    &nbsp; Back
                </button>

                <button
                   type='button'
                   data-testid="submit-exam"
                   onClick={submitAssessment}
                   className='text-lg text-white bg-darkBlue px-12 md:px-16 mr-5 py-3 rounded-lg'
                   data-te-modal-dismiss
                 >
                    Submit Exam
                </button>
            </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default PreviewExamModal