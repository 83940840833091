import React from 'react'

const Card = ({ 
              title, text, color = 'text-gray-900', withStarts=true,
              pending = 0, completed = 0, starts = [], isLoading,
  }) => {

  return (
    <div className='w-full mb-5 md:mb-0 md:w-[200px] py-8 border border-gray-200 rounded-lg bg-white'>
        <p className='text-lg text-center mb-3'>{title}</p>
        {
          withStarts ?
           <div className='flex items-center justify-center'>
               <h1 className={`text-5xl text-center font-bold mb-3 mt-0 ${color}`}> 
                 { (text || text === 0) ? text : '-'}
               </h1>
               <ul className='pl-3'>
                  {
                    (starts?.length && !isLoading) ? 
                     starts?.map( start => {
                        return(
                          <>
                            <li className={`text-md font-medium ${start?.color}`}>
                               {start?.value} &nbsp;
                               {start?.title}
                            </li> 
                          </>
                        )
                     })
                    :
                    <li className='text-lg font-medium -mt-3'>
                      ....
                    </li> 
                  }     
               </ul>
           </div>
          : <h1 className={`text-5xl text-center font-bold mb-3 mt-2 ${color}`}>{text || '-'}</h1>
        }
    </div>
  )
}

export default Card