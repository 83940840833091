import React from 'react'
import {ScaleLoader} from 'react-spinners'

const ScaleLoader_ = () => {
  return (
    <div className='w-[100vw] h-[100vh] fixed top-0 left-0 bg-[rgba(0,0,0,0.05)] block z-[1000]'>            
        <div className='w-full h-full flex items-center justify-center'>
           <span className='px-5 py-3 bg-white'>
               <ScaleLoader
                  size={170}
                  color={"rgb(15, 212, 15)"}
                  loading={true}
                  className="m-0 p-0"
               />
           </span>
        </div>              
     </div>
  )
}

export default ScaleLoader_