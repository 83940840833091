import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function capitalizeText(str) {
  const newText = str.charAt(0).toUpperCase() + str.slice(1);
  return newText;
}

const Applications = ({ data, width }) => {

  const statusColor = {
     pending: 'text-customBlue',
     qualified: 'text-customGreen',
     disqualified:'text-customBlue'//'text-customOrange',
  }

  if (data?.data?.length < 1) {
    return (
      <span className="flex items-end justify-center gap-2 mt-4">
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className="text-3xl text-customOrange"
        />
        <p>No Applications!</p>
      </span>
    );
  }

  return (
    <div
      className="w-full px-2 mb-1 mt-1 overflow-x-scroll"
      // style={{ overflowX: width > 1350 ? null : "scroll" }}
    >
       <div className="md:hidden mt-3">
         {data?.data &&
                  data?.data?.map((application, index) => (
                     <ul className="border border-gray-200 rounded-lg mb-8 p-0">
                        <li className="flex justify-between py-3 px-4">
                           <span className="text-lg">Title:&nbsp;</span>
                           <span className="text-lg">
                              {application?.campaign?.title}
                           </span>
                        </li>
                        <li className="flex justify-between bg-gray-100 py-4 px-4">
                           <span className="text-lg">Company Name:&nbsp;</span>
                           <span className="text-lg">
                           {application?.campaign?.client?.name}
                           </span>
                        </li>
                        <li className="flex justify-between py-4 px-4">
                           <span className="text-lg">Status:&nbsp;</span>
                           <span className={`text-lg ${ statusColor[application?.status] }`}>
                                {
                                capitalizeText(application?.status).toLowerCase() === 'disqualified' ? 
                                 'Pending' : 
                                 capitalizeText(application?.status)
                               }
                           </span>
                        </li>
                        <li className="flex justify-between bg-gray-100 py-4 px-4">
                           <span className="text-lg">Created At:&nbsp;</span>
                           <span className="text-lg">
                           {`${ new Date(application?.campaign?.created_at).toLocaleDateString('EN-gb')}`}
                           </span>
                        </li>
                        <li className="flex justify-between py-4 px-4">
                           <span className="text-lg">Updated At:&nbsp;</span>
                           <span className="text-lg">
                           {`${ new Date(application?.campaign?.updated_at).toLocaleDateString('EN-gb')}`}
                           </span>
                        </li>
                     </ul>
              ))}                    
       </div>

        <div className="flex flex-col overflow-x-auto m-hidden">
        <div className="sm:-mx-6 lg:-mx-8 pb-3">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-x-auto">
              <table className="min-w-full text-left text-sm font-light border border-gray-200">
                <thead className="bg-gray-200 md:bg-white md:shadow-md  font-medium dark:border-neutral-500">
                  <tr>
                    <th scope="col" className="px-6 py-5">S/N</th>
                    <th scope="col" className="px-6 py-5">Title</th>
                    <th scope="col" className="px-6 py-5">Company Name</th>
                    <th scope="col" className="px-6 py-5">Status</th>    
                    <th scope="col" className="px-6 py-5">Created At</th>    
                    <th scope="col" className="px-6 py-5">Updated At</th>                               
                  </tr>
                </thead>
                <tbody>
                  {data?.data &&
                  data?.data?.map((application, index) => (
                    <tr className="border-b dark:border-neutral-500" key={application?.id}>
                      <td className="whitespace-nowrap px-6 py-8 font-medium">
                        {index + 1}
                      </td>
                      <td className="whitespace-nowrap px-6 py-8">
                        {application?.campaign?.title}
                      </td>
                      <td className="whitespace-nowrap px-6 py-8">
                        {application?.campaign?.client?.name}
                      </td>
                      <td className={`whitespace-nowrap px-6 py-8 ${ statusColor[application?.status] }`}>
                        {
                          capitalizeText(application?.status).toLowerCase() === 'disqualified' ? 
                          'Pending' : 
                          capitalizeText(application?.status)
                        }
                      </td>
                      <td className="whitespace-nowrap px-6 py-8">
                        {`${ new Date(application?.campaign?.created_at).toLocaleDateString('EN-gb')}`}
                      </td>
                      <td className="whitespace-nowrap px-6 py-8">
                        {`${ new Date(application?.campaign?.created_at).toLocaleDateString('EN-gb')}`}
                      </td>
                    </tr>
                  ))}                    
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>     
    </div>
  );
};

export default Applications;
