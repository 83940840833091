import React, { useState } from "react";
import Logo from "../../images/TestAssessify.png";
import { useParams, Link, useNavigate } from "react-router-dom";
import { api } from "../../api";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

function LoginPage() {
  const params = useParams();
  const navigate = useNavigate();
  let slug = params.slug;

  if (!isNaN(slug)) {
    slug = parseInt(slug, 10); // Convert to integer if it's a number
  }
  const [copied, setCopied] = useState(false);
  const [code, setCode] = useState("");
  const [isValid, setIsValid] = useState(true);

  const [values, setValues] = useState({
    email: "",
    first_name: "",
    last_name: "",
    slug: slug,
  });

  const validateEmail = (email) => {
    const emailRegex =
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}(?:\.[A-Z]{1,})?$/i;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    if (values.email) {
      setIsValid(validateEmail(values.email));
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const { isLoading, data, isError, error } = useQuery(
    ["verify-campaign-data"],
    async () => {
      const res = await api.examVerification(slug);
      setCode(res.data.resumption_code);
      return res;
    }
  );

  const Register = async (e) => {
    e.preventDefault();
    if (!values.email || !values.first_name || !values.last_name) {
      toast.error("All field is required");
      return;
    }
    try {
      toast.loading("Loading...");
      const res = await api.registerExam({ ...values, resumption_code: code });
      toast.remove();
      console.log(res, "response");
      sessionStorage.setItem("userToken", res.data.token);
      sessionStorage.setItem("user", JSON.stringify(res.data));
      sessionStorage.setItem("view", "viewStandaloneExam");
      if (res.success === true) {
        setModalVisible(true);
      }
      // navigate("/candidate/assessments/candidate-exams", {
      //   state: { from: "/exam/:slug" },
      // });
      setValues({
        email: "",
        first_name: "",
        last_name: "",
      });
    } catch (error) {
      toast.remove();
      console.log(error, "error");
      console.log(error.response, "error.respoe");
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="bg-[#f4ede5] min-h-screen p-4">
      <div className="md:grid place-content-center">
        <div className="md:w-[700px]  bg-white shadow-2xl rounded-lg my-20 md:p-8">
          <div className="flex justify-center mb-10">
            <img src={Logo} alt="testassessify-logo" className="w-fit h-14" />
          </div>
          <div className="flex justify-center">
            <div className="my-10 text-center">
              <h3 className="text-2xl font-normal pt-4">
                TestAssessify Assessments
              </h3>
              <h5 className="font-medium text-lg">Start your test now</h5>
              <p className="pt-8 font-normal text-lg">Test Link Based Exam</p>
            </div>
          </div>
          <div className="flex justify-center mb-6">
            <p className="px-6 py-2 bg-[#EEEEEE] shadow-sm">
              Your Rejoin Code is {code} in case you lose connectivity during
              the Test.{" "}
              <span
                className="px-2 text-primary cursor-pointer"
                onClick={copyToClipboard}
              >
                {copied ? "Copied!" : "Copy to Clipboard"}
              </span>
            </p>
          </div>
          <div className="flex justify-center mb-6 px-7">
            <div className="w-full ">
              <div className="p-4 bg-[#EEE] rounded-t-lg border font-semibold text-lg">
                <h2>Exam Instructions (Please read carefully)</h2>
              </div>
              <div className="p-4 bg-[#F6F6F6] ">
                <p className="mb-4">
                  1. The timer has been set for the exam, and a countdown will
                  display the remaining time. When the timer runs out, the exam
                  will end automatically. No further action will be required
                  after that.
                </p>
                <p className="mb-4">
                  2. You can flag any question that you want.
                </p>
                <p className="mb-4">
                  3. If you don't want to submit any of your provided answers,
                  you can clear them.
                </p>
                <p className="mb-4">
                  4. If you choose to drop this exam, it will not be submitted,
                  and no result will be generated.
                </p>
                <p className="mb-4">
                  5. Once you finish the exam, you cannot resume it.
                </p>
              </div>
            </div>
          </div>
          <hr className="pb-5 px-8" />
          <div className="px-4 md:px-10">
            <div className="">
              <form onSubmit={Register}>
                <div className="w-full md:flex gap-x-6 mb-6 ">
                  <div className=" relative mb-6 md:mb-0 ">
                    <label
                      htmlFor="first_name"
                      className="text-gray-500  text-base font-medium "
                    >
                      First Name
                      <span className="text-red-500 required-dot">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        id="first_name"
                        className="w-full mt-2 rounded-lg  appearance-none border border-gray-700 md:w-96 py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleInputChange}
                        placeholder="Enter your first name"
                      />
                    </div>
                  </div>
                  <div className=" relative ">
                    <label
                      htmlFor="last_name"
                      className="text-gray-500 mb-6 text-base font-medium"
                    >
                      Last Name
                      <span className="text-red-500 required-dot">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        id="last_name"
                        className="w-full mt-2 rounded-lg  appearance-none border border-gray-700 md:w-96 py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleInputChange}
                        placeholder="Enter your first name"
                      />
                    </div>
                  </div>
                </div>
                <div className="relative ">
                  <label
                    htmlFor="email"
                    className="text-gray-500 mb-6 text-base font-medium"
                  >
                    Email
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <div className="flex">
                    <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                      <svg
                        width="15"
                        height="15"
                        fill="currentColor"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      id="email"
                      className=" rounded-r-lg flex-1 appearance-none border border-gray-700 w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                      placeholder="Enter your email"
                    />
                  </div>
                  {!isValid && (
                    <p className="text-center text-red-500 p-2">
                      Please enter a valid email address.
                    </p>
                  )}
                </div>
                {/* <Link
                  to={`candidate/assessments/candidate-exams/${user?.user_group_id}`}
                >
                  exam
                </Link> */}
                <button
                  type="submit"
                  className="py-4 px-4 mt-10 bg-primary hover:bg-primary focus:ring-primary focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 !text-center text-xl font-semibold shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                >
                  Start a New Test
                </button>
              </form>
            </div>
            <div className="mt-6">
              <div className="flex gap-x-6 items-center">
                <div className="w-full  border "></div>
                <div className="d">OR</div>
                <div className="w-full  border"></div>
              </div>
            </div>

            <div className="pb-10">
              <Link to={`/rejoin-exam`}>
                <button
                  type="button"
                  className="py-4 px-4 mt-6 bg-gray-200 hover:bg-gray-200  text-black w-full transition ease-in duration-200 !text-center text-xl font-semibold shadow-sm focus:outline-none  focus:ring-offset-2 rounded-lg "
                >
                  Rejoin the Lost Test
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default LoginPage;
