import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import logo from "../../images/TestAssessify.png";
import toast from "react-hot-toast";
import { api } from "../../api";

function verifyUserEmail() {
  const [emailDetails, setEmailDetails] = useState([]);
  const params = useParams();
  const fetchData = async () => {
    try {
      toast.loading("loading...");
      const res = await api.verifyRegisteredUserEmail(params.id1, params.id2);
      toast.remove();
      toast.success(res.message);
      setEmailDetails(res.data);
      //   navigate("/");
    } catch (error) {
      toast.remove();
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    // call the function
    fetchData();
  }, []);

  return (
    <div className="bg-white p-10">
      <div className="w-full p-3 h-26 bg-inherit sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="bg-cover bg-no-repeat  bg-hero-img w-full w-[900px] rounded-xl p-10 mt-16 shadow-2xl lg:h-[65vh]">
          <div className="md:mt-16  shadow-2xl rounded-xl bg-white w-[600px] flex justify-center mx-auto">
            <div className="flex flex-col  w-full">
              <div className="flex flex-col justify-center px-4 pt-8 my-auto md:justify-start md:pt-0 md:px-10 lg:px-10">
                <p className="text-3xl text-darkBlue pt-8 mb-2 font-semibold">
                  Welcome to Test Assessify
                </p>
                <p className="text-base font-normal text-slate-5">
                  Dear {emailDetails?.name} your email address has been verified
                  .
                </p>

                <div className="pt-12 pb-12  text-center">
                  <p className="text-xl">
                    Proceed to &nbsp;
                    <Link to="/" className="font-semibold underline">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default verifyUserEmail;
