import React, { useContext } from "react";
import { PersonRounded, MenuRounded } from "@material-ui/icons";
import Logo from "../../images/TestAssessify.png";
import { UserContext } from "../../context";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { api } from "./../../api/index";

const Header = ({ sidebarToggle, windowWidth }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const logOut = async () => {
    const userData = sessionStorage.getItem("user");
    const token = sessionStorage.getItem("userToken");
    try {
      if (userData && token) {
        const response = await api.logout();
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("noapply");
        toast.success(response.message);
      }
      navigate("/");
    } catch (error) {
      toast.remove();
      toast.error(error.response.data.error);
    }
  };
  return (
    <div className="block w-full h-[50px] bg-white sticky top-0 z-20 shadow-md mobile-navbar">
      <div className="h-full px-5 py-0 flex items-center justify-between">
        {windowWidth < 768 && (
          <button onClick={sidebarToggle}>
            <MenuRounded />
          </button>
        )}
        <div className="h-full">
          <img
            src={Logo}
            alt="Test Assessify"
            className="w-36 h-full object-contain"
          />
        </div>
        <div className="flex items-center">
          <div className="flex mr-4 items-end">
            <Link to="/candidate/profile">
              <PersonRounded />
            </Link>
            <span className="mr-2" style={{ fontSize: "13px" }}>
              {user?.name || null}
            </span>
          </div>
          <button
            className="px-2 py-1 rounded capitalize text-sm"
            style={{ border: "1px solid #f68158", color: "#f68158" }}
            onClick={logOut}
          >
            log out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
