import React, { useContext, useState } from "react";
import logo from "../../images/TestAssessify.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { api } from "../../api";
import { UserContext } from "../../context";

function greetTime() {
  const today = new Date();
  const hourOfDay = today.getHours(); //The current hour of today
  if (hourOfDay > 11 && hourOfDay <= 16) {
    return "afternoon";
  } else if (hourOfDay > 16) {
    return "evening";
  } else {
    return "morning";
  }
}

function login() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutateAsync: onLogin } = useMutation(api.login);
  const { user, setUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const previousRoute = location.state?.from;
  const referralRoutePattern = /^\/application\/:slug\/\w+$/;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (payload) => {
    try {
      toast.loading("Logging in...");
      const data = await onLogin({
        ...payload,
        account_type: "candidate",
        slug:
          sessionStorage.getItem("slug") === "null"
            ? null
            : sessionStorage.getItem("slug"),
        referral:
          sessionStorage.getItem("referral") === "null"
            ? null
            : sessionStorage.getItem("referral"),
      });
      toast.remove();
      sessionStorage.setItem("view", "showSideBar");
      const campaignId = sessionStorage.getItem('profile-campaign-id')
      setUser((prev) => {
        return { ...prev, profile: data.data || null };
      });
      toast.success("Logged in successfully...");
      
      if(campaignId !== '' && campaignId !== null ){
        navigate(`/candidate/profile?campaign_id=${campaignId}`);
      }
      else if (
        previousRoute === "/application/:slug" ||
        referralRoutePattern.test(previousRoute) ||
        data.data.campaign_title !== null
      ) {
        navigate("/candidate");
        // navigate("/candidate/profile");
        // toast.success(data.data.message);
      }
      else {
        navigate("/candidate", { replace: true });
      }
      sessionStorage.setItem("userToken", data.data.token);
      sessionStorage.setItem("user", JSON.stringify(data?.data));
    } catch (error) {
      toast.remove();
      toast.error(error.response?.data.error);
    }
  };
  return (
    <div className="bg-white p-4 lg:p-6  ">
      <div className="w-full pb-4 h-26 bg-inherit sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>
      <div className="flex justify-center items-center   mt-20 2xl:mt-0  2xl:h-screen">
        <div className="bg-cover bg-no-repeat  bg-hero-img w-full md:w-[600px] lg:w-[900px] 2xl:w-[1440px] 2xl:h-[800px]  rounded-xl p-2 lg:p-10 sm:shadow-2xl ">
          <div className="my-2 shadow-2xl rounded-[8px] bg-white w-full lg:w-1/2 2xl:h-[600px] 2xl:mt-28 p-4 2xl:p-6">
            <div className="flex flex-col w-full">
              <div className="flex flex-col justify-center lg:px-4 pt-8 my-auto md:justify-start md:pt-0  2xl:px-10">
                <form
                  className="flex flex-col pt-3 md:pt-8"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="2xl:mb-10">
                    <div className="flex md:items-center justify-start w-full  overflow-hidden">
                      <h1 className="text-3xl md:w-3/4 object-contain font-medium text-darkBlue">
                        Good {greetTime()}
                      </h1>
                    </div>
                    <p className="my-2 text-baseFont text-slate-500">
                      Please sign into your account
                    </p>
                  </div>
                  <div className="flex flex-col pt-5">
                    <div className="flex relative mb-4 2xl:mb-10">
                      <span className=" inline-flex rounded-l-lg  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                        <svg
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                        </svg>
                      </span>
                      <input
                        type="email"
                        id="email"
                        className=" flex-1 appearance-none rounded-r-lg border border-gray-300 w-full py-4 2xl:py-8 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848]  focus:border-transparent"
                        {...register("email", {
                          required: true,
                        })}
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-12">
                    <div className="flex relative">
                      <span className=" inline-flex rounded-l-lg items-center px-3 border bg-white   border-gray-300 text-gray-500 shadow-sm text-sm">
                        <svg
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z"></path>
                        </svg>
                      </span>
                      <div className="relative flex  w-full focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className=" flex-1 appearance-none  border-y border-gray-300 w-full py-4 px-4 2xl:py-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none "
                          {...register("password", {
                            required: true,
                          })}
                          placeholder="Enter your password"
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="inline-flex rounded-r-lg  appearance-none  items-center px-3 border-t bg-white border-r border-b border-gray-300 text-gray-500 shadow-sm text-sm cursor-pointer focus:outline-none "
                        >
                          {showPassword ? (
                            <AiOutlineEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-full px-4 2xl:py-4 py-2 rounded-lg text-base font-semibold text-center text-white transition duration-200 ease-in bg-[#88a848] shadow-md  focus:outline-none focus:ring-2"
                  >
                    <span className="w-full text-xl 2xl:text-2xl">Login</span>
                  </button>
                </form>
                {/* <div className="mt-4 ">
                  {" "}
                  <button class="w-full flex justify-center px-4 py-2 border  gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                    <img
                      class="w-6 h-6"
                      src="https://www.svgrepo.com/show/475656/google-color.svg"
                      loading="lazy"
                      alt="google logo"
                    />
                    <span>Login with Google</span>
                  </button>
                </div> */}
                <div className="pt-8   flex justify-end">
                  <p className="text-xl">
                    <Link
                      to="/auth/forgot-password"
                      className="font-semibold underline"
                    >
                      Forgot Password ?
                    </Link>
                  </p>
                </div>
                <div className="pt-8  text-center">
                  <p className="text-xl">
                    Don&#x27;t have an account?&nbsp;
                    <Link to="/register" className="font-semibold underline">
                      Sign Up
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default login;
