import { configureStore , combineReducers } from '@reduxjs/toolkit'; 
import {  persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
//reducers
import candidateExamSlice from './features/candidate-exam/candidate-exam-slice';
 
  const persistConfig = { key: 'root', version: 1, storage, }

  const reducers = combineReducers({
     candidateExam: candidateExamSlice,
   })

 const persistedReducers = persistReducer( persistConfig , reducers )

 export const store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    devTools : process.env.NODE_ENV !== 'production',
  })