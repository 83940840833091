import React from 'react'
import { AssessementContext } from '../../../../AssessmentContext';

const InputQuestions = ({question,setShowUnusualModal}) => {

  const { assessment, setAssessment } = React.useContext( AssessementContext )

  const throwUnsualWarning = (event) => {
     const form = document.querySelector(`form.form-${question.id}`)
     form.reset()     
     setShowUnusualModal(true)
  }

  const handleSavedAnswer = (event,answer) => {
    setAssessment(prevAssessment => {
      return { 
                ...prevAssessment, 
                assessements: {...prevAssessment?.assessements,
                  questions: [
                     ...prevAssessment?.assessements?.questions?.map( question_ => {
                        if(question?.id === question_?.id){
                           return {
                              ...question_, answers : [...question_?.answers?.map( ans => {
                              if(answer?.id === ans?.id ){
                                 return {...ans, text: event.target.value , status: true }
                              }
                              else return {...ans}
                              })]
                           }
                        }
                        else return {...question_}
                     })
                  ]
                }
          }
     })   
  }

  return (
    <div>     
       <form className={`form-${question.id}`}>
       <div className='flex justify-between flex-wrap mt-5'>           
            <div className='md:flex justify-between flex-wrap'>
              <div className='md:flex items-center w-[100%] md:w-[50%] pr-2 mb-5'>
                   {
                    question.answers.map( answer => {
                      return(
                          <input 
                              type="text" name=""
                              defaultValue={ answer?.status ? answer?.text : '' }
                              onCopy={ throwUnsualWarning }
                              onPaste={ throwUnsualWarning }
                              onCut={ throwUnsualWarning }                               
                              onBlur={ (event) => handleSavedAnswer(event,answer) }
                              className='w-[90%] md:w-[500px] text-lg mt-4 sm:mt-0 mr-5 py-3 px-3 border border-gray-200 rounded-lg focus:border-green-500 focus:outline-none'
                          /> 
                      )
                   })}                 
              </div>               
            </div>
        </div>
       </form>
    </div>
  )
}

export default InputQuestions