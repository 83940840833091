import React from 'react'
import { Ripple, initTE, } from "tw-elements";
import { Link } from 'react-router-dom';
import JobCard from './JobCard';

const JobListings = ({listings, isLoading}) => {

 React.useEffect( () => {
    initTE({ Ripple });
 }, [] )

 const getRoute = () => {
   const hostname = window.location.hostname;
   if (hostname.includes("playpen") || hostname.includes("localhost")) {
     return "http://playpen.testassessify.com";
   } else {
     return "https://testassessify.com";
   }
 };

  return (
    <>
      <ol className="border-l-2 border-customGreen"> 
         {
            !isLoading ?
               listings?.length ?
                     listings?.map( (listing,id) => {
                         return(
                            <li key={id}>
                            <div className="flex-start md:flex">
                            <div
                                className="-ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-customGreen text-white">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="h-4 w-4">
                                <path
                                    fill-rule="evenodd"
                                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                    clip-rule="evenodd" />
                                </svg>
                            </div>
                            <div
                                className="mb-10 ml-6 w-[400px] block max-w-md rounded-lg shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10"
                             > 
                                  <JobCard 
                                    title={listing?.title}
                                    status = {listing?.campaign?.application_status}
                                    company={listing?.client?.name}
                                    type={'Full Time'}
                                    location={'Lagos'}
                                    date = { new Date(listing?.created_at !== null && listing?.created_at ) }
                                    link={`${getRoute()}/job-listings/apply/${listing?.slug}?campaign-id=${listing?.id}`}
                                    key={listing?.id}
                                    closed = { 
                                       new Date().getTime() < new Date(listing?.end_date).getTime() ? true : false
                                     }
                                />
                            </div>
                            </div>
                    </li>
                         )
                     })
               : 
               <div className='my-[3rem] md:my-[6rem]'>
                   <img 
                      src="/images/empty-test-q-a.jpg" alt="no exams" 
                      className='block w-[300px] h-[300px] mx-auto' 
                  />
                  <h3 className='mt-3 text-lg text-center'>
                     There is no any job listings available yet.
                  </h3>
               </div>
            : null 
         }
      </ol>
    </>
  )
}

export default JobListings