import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import { CandidateContext } from "./CandidateContext";
import {
  Navigate,
  Route,
  Routes as CandidateRoutes,
  useLocation,
  Link,
} from "react-router-dom";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import useWindowDimensions from "../../hooks/useWindowSize";
import { useSelector } from "react-redux";
//ROUTES
import Assessment from "./assessments/Assessment";
import Dashboard from "./dashboard/Dashboard";
import Documentation from "./documentation/Documentation";
import ExamHistory from "./examHistory/ExamHistory";
import Settings from "./settings/Settings";
import Notifications from "./notifications/Notifications";
import Profile from "./profile/Profile";
import Vacancies from "./vacancies/Vacancies";
import { pageRoutes } from "../../PageRoutes";
import ExamResult from "./exam-result/ExamResult";
import LostHere from "../../assets/icons/lost.svg";
import FeedBack from "./FeedBack";
import NotFound from "./../../notFound";
import NotFoundPage from "../../components/404Page/NotFoundPage";
import NoAccess from "./../../noAccess";
import Redirect from "./dashboard/Redirect";
import { useDispatch } from "react-redux";
import { updateProfileStatus } from "../../features/candidate-exam/candidate-exam-slice";

const CandidateRoutes_ = () => {
  const [candidateContext, setCandidateContext] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false)
  const [isOpen, setIsOpen] = useState(
    //hide sidebar in mobile by default
    window.matchMedia("(max-width: 700px)").matches ? false : true
  );
  const [currentPageID, setCurrentPageID] = useState(0);
  const { width } = useWindowDimensions();
  const location = useLocation();
  const disabled = process.env.REACT_APP_DISABLE_ROUTE;
  const { profileComplete } = useSelector((state) => state.candidateExam);
  const dispatch = useDispatch()

  const toggleSidebar = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const setSelectedPageID = (id) => {
    setCurrentPageID(id);
  };

  const standaloneExam = sessionStorage.getItem("view");
  const userData = sessionStorage.getItem("user");

  useEffect(() => {
     dispatch( updateProfileStatus(false) )
  }, [])
  
  useEffect(() => {
    if (
      !location.pathname.includes("candidate-assessment") &&
      standaloneExam !== "viewStandaloneExam"
    ) {
      document.querySelector("#sidebar").style.display = "flex";
      if (window.matchMedia("(max-width: 700px)").matches) {
        document.querySelector(".mobile-navbar").style.display = "block";
      }
    }
    // disabled forcing candidate to update profile
    if(userData !== null){
      if(!location.pathname.includes('/candidate/profile') && !profileComplete){
        const userProfileCompleted = JSON.parse(userData)?.user?.profile_completion_status
        if(!userProfileCompleted) setShowProfileModal(true)
      }
    }
  }, [location.pathname]);

  return (
    <React.Fragment>
      {width < 768 && (
        <Header sidebarToggle={toggleSidebar} windowWidth={width} />
      )}
      <div className="flex layout-wrapper">
        <Sidebar
          pages={pageRoutes}
          setPage={setSelectedPageID}
          currentPage={currentPageID}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          windowWidth={width}
        />
        <div
          className="p-2 md:p-5"
          id="parent-container"
          style={{
            flex: "4",
            backgroundColor: "#f2f3f7",
            height: width < 768 ? "calc(100vh - 43px)" : "100vh",
            overflowY: "scroll",
          }}
        >
          { showProfileModal ? 
            <Redirect 
               handleRedirect={setShowProfileModal}
               title={`
                Your profile is incomplete, 
                Please update your profile and save your changes to proceed`
               }
               btnTitle={'Update My Profile'}
               to={'/candidate/profile'}
             /> 
          : null }
          <CandidateContext.Provider
            value={{ candidateContext, setCandidateContext }}
          >
            <CandidateRoutes>
              <Route
                path="/"
                element={<Navigate to={"/candidate/dashboard"} />}
              />
              <Route
                path="/dashboard"
                element={
                  disabled === "disable-route" ? (
                    <NoAccess />
                  ) : (
                    <Dashboard width={width} />
                  )
                }
              />
              <Route
                path="/assessments/*"
                element={<Assessment isOpen={isOpen} setIsOpen={setIsOpen} />}
              />
              <Route path="/documentation" element={<Documentation />} />
              <Route path="/exam-history" element={<ExamHistory />} />
              <Route path="/exam/result" element={<ExamResult />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/profile" element={disabled === "disable-route" ? <NoAccess />: <Profile />} />
              <Route path="/profile/:uid1/:uid2" element={disabled === "disable-route" ? <NoAccess />: <Profile />} />
              <Route path="/vacancies" element={<Vacancies />} />
              <Route path="/feedback" element={<FeedBack />} />
              {/*  404 page  */}
              <Route
                path="*"
                element={<NotFoundPage url={"/candidate/dashboard"} />}
              />
            </CandidateRoutes>
          </CandidateContext.Provider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CandidateRoutes_;
