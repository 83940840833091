import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faSquareMinus,
} from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import {
  detectBrowser,
  detectOS,
  detectBrowserVersion,
  detectScreenResolution,
  detectJSEnabled,
} from "./utils";

const CheckCompatibility = ({ setShowModal, startExam, id }) => {
  const backdropRef = useRef(null);
  const [checks, setChecks] = useState({
    os: "",
    browser: "",
    browserVersion: "",
    screenRes: "",
    javascript: "",
  });
  const [fullCheck, setFullCheck] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      detectBrowser(setChecks);
    }, 2000);
    setTimeout(() => {
      detectOS(setChecks);
    }, 2000);
    setTimeout(() => {
      detectBrowserVersion(setChecks);
    }, 2000);
    setTimeout(() => {
      detectScreenResolution(setChecks);
    }, 2000);
    setTimeout(() => {
      detectJSEnabled(setChecks);
    }, 2000);
    setTimeout(() => {
      setFullCheck(true);
    }, 2500);
  }, []);

  return (
    <div
      className="fixed inset-0 z-50 w-[100vw] h-[100vh] overflow-x-hidden bg-gray-900 bg-opacity-50 flex justify-center items-center"
      ref={backdropRef}
      style={{ display: "none" }}
      id={id}
    >
      <div className="bg-white rounded-lg  mx-auto p-4 w-[100vw] md:w-[600px]">
        <div className="bg-darkBlue p-4 rounded-md">
          <h1 className="mb-3 text-white text-2xl">
            Check your system compatibility
          </h1>
          <p className="text-white text-sm">
            To ensure uninterrupted exam delivery, Kindly check your system
            <br />
            compatibility and proximity to our exam server.
          </p>
        </div>
        <div className="flex items-center justify-between mt-4 p-2">
          <p className="flex-1 text-slate-400">Operating System</p>
          <p className="flex-1">
            {checks.os === "" ? "Processing..." : checks.os}
          </p>
          {checks.os === "" ? (
            <p className="flex-1">...</p>
          ) : (
            <p className="flex items-center gap-1 flex-1 text-customGreen">
              <FontAwesomeIcon icon={faCircleCheck} />
              Compatible
            </p>
          )}
        </div>
        {/*JAVASCRIPT*/}
        <div className="flex items-center justify-between mt-4 p-2">
          <p className="flex-1 text-slate-400">Browser</p>
          <p className="flex-1">
            {checks.browser === "" ? "Processing..." : checks.browser}
          </p>
          {checks.browser === "" ? (
            <p className="flex-1">...</p>
          ) : checks.browser.toLowerCase() === "unknown" ? (
            <p className="flex items-center gap-1 flex-1 text-red-500">
              <FontAwesomeIcon icon={faSquareMinus} />
              Not compatible
            </p>
          ) : (
            <p className="flex items-center gap-1 flex-1 text-customGreen">
              <FontAwesomeIcon icon={faCircleCheck} />
              Compatible
            </p>
          )}
        </div>
        {/*JAVASCRIPT*/}
        <div className="flex items-center justify-between mt-4 p-2">
          <p className="flex-1 text-slate-400">Browser Version</p>
          <p className="flex-1">
            {checks.browserVersion === ""
              ? "Processing..."
              : checks.browserVersion}
          </p>
          {checks.browserVersion === "" ? (
            <p className="flex-1">...</p>
          ) : checks.browserVersion.toLowerCase() === "unknown" ? (
            <p className="flex items-center gap-1 flex-1 text-red-500">
              <FontAwesomeIcon icon={faSquareMinus} />
              Not compatible
            </p>
          ) : (
            <p className="flex items-center gap-1 flex-1 text-customGreen">
              <FontAwesomeIcon icon={faCircleCheck} />
              Compatible
            </p>
          )}
        </div>
        {/*JAVASCRIPT*/}
        <div className="flex items-center justify-between mt-4 p-2">
          <p className="flex-1 text-slate-400">Screen Resolution</p>
          <p className="flex-1">
            {checks.screenRes === "" ? "Processing..." : checks.screenRes}
          </p>
          {checks.screenRes === "" ? (
            <p className="flex-1">...</p>
          ) : checks.screenRes.toLowerCase() === "unknown" ? (
            <p className="flex items-center gap-1 flex-1 text-red-500">
              <FontAwesomeIcon icon={faSquareMinus} />
              Not compatible
            </p>
          ) : (
            <p className="flex items-center gap-1 flex-1 text-customGreen">
              <FontAwesomeIcon icon={faCircleCheck} />
              Compatible
            </p>
          )}
        </div>
        {/*JAVASCRIPT*/}
        <div className="flex items-center justify-between mt-4 p-2">
          <p className="flex-1 text-slate-400">Javascript</p>
          <p className="flex-1">
            {checks.javascript === "" ? "Processing..." : checks.javascript}
          </p>
          {checks.javascript === "" ? (
            <p className="flex-1">...</p>
          ) : (
            <p className="flex items-center gap-1 flex-1 text-customGreen">
              <FontAwesomeIcon icon={faCircleCheck} />
              Compatible
            </p>
          )}
        </div>
        {fullCheck &&
          (checks.screenRes.toLowerCase() === "unknown" ||
          checks.browserVersion.toLowerCase() === "unknown" ||
          checks.browser.toLowerCase() === "unknown" ? (
            <p className="text-red-500 mt-4 bg-red-100 border border-red-500 py-4 px-2 text-lg">
              Your system is not fully compatible with our software, Please{" "}
              <br />
              ensure your device has match our system requirement
              <Link
                onClick={() => setShowModal(false)}
                to={"/candidate/assessments/view-assessments"}
                className="bg-red-500 text-white text-md py-3 px-8 ml-3 mt-3 rounded-lg"
              >
                Go back
              </Link>
            </p>
          ) : (
            <div
              className="md:flex items-center justify-between w-full mt-5 text-baseFont text-white p-3 rounded-md"
              style={{
                backgroundColor: "#ECFFCC",
                border: "1px solid #87AA4B",
              }}
            >
              <p className="text-customGreen w-full text-sm mb-3 md:mb-0">
                Your system is fully compatible with our software, <br /> you
                can take assessments anytime.
              </p>
              <button
                id="continue"
                className="text-white text-sm px-8 py-3 rounded-md"
                style={{ backgroundColor: "#87AA4B" }}
                onClick={() => {
                  startExam();
                  document.querySelector(`#${id}`).style.display = "none";
                }}
              >
                Continue
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CheckCompatibility;
