import React, { useState, useRef } from "react";
import { api } from "../../../../../../api";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

function BookexamModal({ closeModal, getAssessmentMutation, refetch }) {
  const [openTime, setOpenTime] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const [selectedTime, setSelectedTime] = useState("Select time");
  const [selectedState, setSelectedState] = useState("Select state");
  const [selectedDate, setSelectedDate] = useState("Select date");
  const [selectedDateId, setSelectedDateId] = useState(null);
  const [selectOutletId, setSelectOutletId] = useState(null);
  const [selectTimetId, setSelectTimeId] = useState(null);

  const timeRef = useRef(null);
  const stateRef = useRef(null);
  const dateRef = useRef(null);

  const closeOpenMenus = (e) => {
    if (timeRef.current && openTime && !timeRef.current.contains(e.target)) {
      setOpenTime(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  const group_id = sessionStorage.getItem("group_id");
  const user_group_id = sessionStorage.getItem("user_group_id");

  const { isLoading, data, isError, error } = useQuery(["id"], async () => {
    const data = await api.getBookings(group_id);
    return data;
  });
  const [currentDate, setCurrentDate] = useState({});

  const handleTimeSelection = (time) => {
    setSelectedTime(time);
    setOpenTime(false);
  };

  const handleBookExam = async (e) => {
    e.preventDefault();

    if(!selectedDateId){
       toast.error('Please select date')
    }
    else if (selectedTime === "Select time" || selectedTime === ""){
      toast.error('Please select time')
    }
    else {
      let payload = {
        outlet_id: selectOutletId || null,
        date_id: selectedDateId,
        preferred_time_slot: selectedTime,
        user_group_id: user_group_id,
      };
  
      try {
        toast.loading("loading...");
        const res = await api.createBooking({
                               group_id: user_group_id,
                               payload,
                            });
        toast.remove();
        // getAssessmentMutation.mutate()
        toast.success(res.message);
        refetch();
        closeModal(false);
      } catch (error) {
        toast.remove();
        toast.error(error.response.data.error);
        refetch();
      }
    }
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen grid place-items-center bg-black bg-opacity-50 p-10 ">
      <div className="">
        <div className="min-h-[12rem] md:w-[600px] bg-white rounded-[32px] flex flex-col justify-center p-6 md:p-16 relative">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Book Exam  
          </h3>
          <form onSubmit={handleBookExam}>
            <div className="mb-4">
              <label htmlFor="date" className="mb-4 text-lightGrey text-xs">
                Date
              </label>
              <div
                className="select-none relative  rounded-lg border-transparent flex-1 appearance-none  w-full  bg-customGrey text-gray-700 cursor-pointer text-base"
                
              >
                <div
                  className="flex justify-between items-center py-4 px-4 "
                  onClick={() => setOpenDate(!openDate)}
                >
                  {selectedDate}
                </div>
                {openDate && (
                  <div>
                    <div className="mt-2 max-h-40 overflow-y-auto bg-white overflow-x-hidden cursor-pointer absolute w-full left-0 shadow-md z-40 ">
                      {data?.data?.group_exam_dates.length === 0 ? (
                        <p className="text-center text-red-500">
                          Exam date has not been slated for you
                        </p>
                      ) : (
                        <div>
                          {data?.data?.group_exam_dates?.map((date, index) => (
                            <div
                              onClick={() => {
                                setSelectedDate(date.booking_date);
                                setSelectedDateId(date.id);
                                setCurrentDate(date);
                                setOpenDate(false);
                              }}
                              className="py-2 hover:bg-gray px-4"
                              key={index}
                            >
                              <div> {date.booking_date} </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {
              ( data?.data?.type === "physical" || 
                data?.data?.group_exam_dates?.find( date => date?.id === selectedDateId)
                ?.group_exam_date_outlets?.length
              ) && (
              <div className="mb-4">
                <label htmlFor="state" className="mb-4 text-lightGrey text-xs">
                  State
                </label>
                <div
                  className="select-none relative  rounded-lg border-transparent flex-1 appearance-none  w-full  bg-customGrey text-gray-700 cursor-pointer text-base"
                  
                >
                  <div
                    className="flex justify-between items-center py-4 px-4 "
                    onClick={() => setOpenState(!openState)}
                  >
                    {selectedState}
                  </div>
                  {openState && (
                    <div>
                      <div className="mt-2 max-h-40 overflow-y-auto bg-white overflow-x-hidden cursor-pointer absolute w-full left-0 shadow-md z-40">
                        {Object.keys(currentDate).length === 0 ? (
                          <p className="text-center text-red-500">
                            select date first
                          </p>
                        ) : (
                          <div>
                            {currentDate &&
                              currentDate?.group_exam_date_outlets?.map(
                                (state, index) => (
                                  <div
                                    onClick={() => {
                                      setSelectedState(
                                        state?.outlet?.state?.name
                                      );
                                      setSelectOutletId(state?.outlet.id);
                                      setOpenState(false);
                                    }}
                                    className="py-2 hover:bg-gray px-4"
                                    key={index}
                                  >
                                    <div> {state?.outlet?.state?.name} </div>
                                  </div>
                                )
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="mb-4">
              <label htmlFor="time" className="mb-6 text-lightGrey text-xs">
                Time
              </label>
              <div
                className="select-none relative  rounded-lg border-transparent flex-1 appearance-none  w-full  bg-customGrey text-gray-700 cursor-pointer text-base"
                
              >
                <div
                  className="flex justify-between items-center py-4 px-4 "
                  onClick={() => setOpenTime(!openTime)}
                >
                  {selectedTime}
                </div>
                {openTime && (
                  <div>
                    <div className="mt-2 max-h-40 overflow-y-auto bg-white overflow-x-hidden cursor-pointer absolute w-full left-0 shadow-md z-30">
                      {Object.keys(currentDate).length === 0 ? (
                        <p className="text-center text-red-500">
                          select date first
                        </p>
                      ) : (
                        <div>
                          {currentDate?.time_slots &&
                            JSON.parse(currentDate?.time_slots).map(
                              (time, index) => (
                                <div
                                  onClick={() => handleTimeSelection(time?.time)}
                                  className="py-2 hover:bg-gray px-4"
                                  key={index}
                                >
                                  <div>{time?.time} </div>
                                </div>
                              )
                            )}
                        </div>
                      )}
                    </div>
                    
                  </div>
                )}
              </div>
            </div>

            <div className="flex gap-x-10 justify-center">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-darkBlue  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-white text-sm font-medium text-darkBlue  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                onClick={() => closeModal(false)}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-darkBlue  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-darkBlue text-sm font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
              >
                Book
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BookexamModal;
