import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useQuery, useQueries, useMutation } from "react-query";
import { api } from "../../../../api";
import toast from "react-hot-toast";
import { useCustomToast } from "../../../../components/customtoast/CustomToastProvider";
import { ScaleLoader } from "react-spinners";
import CustomDropdown from "../../../../components/Global/CustomDrpdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useDebounce = (fn, delay) => {
  let timeout;
  // return function that takes arg and apply to the function
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

function AddEducation({ closeModal, refetchEducation, refetchStatus }) {

  const customToast = useCustomToast();
  const [institutionSearch, setInstitutionSearch] = useState('')
  const [searchInstitutionLoading, setSearchInstitutionLoading] = useState(false)
  const [searchCourseLoading, setSearchCourseLoading] = useState(false)
  const [institutions, setInstitutions ] = useState([])
  const [courses, setCourses] = useState([]);
  const [startDate_, setStartDate_] = useState(new Date());
   
  // fetch data
  const { isLoading: institutionLoading, refetch} = useQuery(
    "get-institution",
     () => {
         return api.getInstitutions(institutionSearch)
     },
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: response => {
          setInstitutions(response?.data?.map( ins => {
             return {label: ins?.name, value: ins?.id}
          }))
      }
    }
  );

  const getInstitutionsMutation = useMutation(api.getInstitutions,
    {
      onSuccess: response => {
          setInstitutions(response?.data?.map( ins => {
             return {label: ins?.name, value: ins?.id}
          }))
      }
    }
  )

  const addInstitutionMutation = useMutation(api.addInstitution, {
     onMutate: () => {
        toast.loading('Loading...')
     },
     onError: error => {
        toast.remove()
        toast.error(error?.response?.message)
     },
     onSuccess: data => {
        toast.remove()
        toast.success('Institution Added Successfully!')
        setOtherInstitution('')
        setIsOthers(false)
        refetch()
        setSelectedInstitutionId(data?.data?.id || "");
        setSelectedInstitution(data?.data?.name || "");
        setInstitutionValid(true);
     }
  })

  // fetch data
  const { isLoading,} = useQuery("get-courses", api.getCourses, {
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
     onSuccess: data => {
      setCourses(
        data?.data?.map((ins) => {
          return { label: ins?.title, value: ins?.id };
        })
      );
     }
  });

  const getCoursesMutation = useMutation(api.getCourses, {
    onSuccess: (response) => {
      setCourses(
        response?.data?.map((ins) => {
          return { label: ins?.title, value: ins?.id };
        })
      );
      setTimeout( () => setSearchCourseLoading(false) , 100 )
    },
  });

  const { data: degrees } = useQuery(
    "get-degree",
     api.getDegrees,
     {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: response => response?.data
     }
    );

  const { data: classOfDegrees } = useQuery(
    "get-classOfDegrees",
    api.getClassOfDegrees,
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: (response) => response?.data,
    }
  );
  // End

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isOthers, setIsOthers] = useState(false)
  const [otherInstitution, setOtherInstitution] = useState('')

  // handle seletced states
  const [selectInstitution, setSelectedInstitution] = useState("");
  const [selectCourse, setSelectedCourse] = useState("");
  const [selectDegree, setSelectedDegree] = useState("");
  // const [selectState, setSelectedState] = useState("");
  const [selectClassOfDegree, setSelectedClassOfDegree] = useState("");
  const [degreeId, setDegreeId] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [selectInstitutionId, setSelectedInstitutionId] = useState(null);
  const [classOfDegreeId, setClassOfDegreeId] = useState(null);

  // for validation
  const [institutionValid, setInstitutionValid] = useState(true);
  const [courseValid, setCourseValid] = useState(true);
  const [degreeValid, setDegreeValid] = useState(true);
  const [classofDegreeValid, setClassOfDegreeValid] = useState(true);
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);

  // Start of Degree
  const filteredInstitutions = (inputValue) => {
    return institutions?.data
      .filter((institution) =>
        institution.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((i) => ({
        value: i.id,
        label: i.name,
        key: i.id.toString(),
      }));
  };
  // End

  const loadOptions = (inputValue, callback) => {
    callback(filteredInstitutions(inputValue));
  };
  // End

  // Start of Degree filter
  const filteredDegree = (inputValue) => {
    return degrees?.data
      .filter((degree) =>
        degree.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((deg) => ({
        value: deg.id,
        label: deg.name,
        key: deg.id.toString(),
      }));
  };
  const degreeOptions = (inputValue, callback) => {
    callback(filteredDegree(inputValue));
  };
  // End

  // Start of courses filter
  const filteredCourses = (inputValue) => {
    return courses?.data
      .filter((course) =>
        course.title.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((c) => ({
        value: c.id,
        label: c.title,
        key: c.id.toString(),
      }));
  };
  const courseOptions = (inputValue, callback) => {
    callback(filteredCourses(inputValue));
  };
  // End

  // Start of class of degree filter
  const filteredClassOfDegrees = (inputValue) => {
    return classOfDegrees?.data
      .filter((classOfDegree) =>
        classOfDegree.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((cg) => ({
        value: cg.id,
        label: cg.name,
        key: cg.id.toString(),
      }));
  };
  const classOptions = (inputValue, callback) => {
    callback(filteredClassOfDegrees(inputValue));
  };
  // End

  const createEducation = async (e) => {
    e.preventDefault();

    setInstitutionValid(selectInstitution !== "");
    setCourseValid(selectCourse !== "");
    setDegreeValid(selectDegree !== "");
    setClassOfDegreeValid(selectClassOfDegree !== "");
    setStartDateValid(startDate !== "");
    setEndDateValid(endDate !== "");

    if (
      !institutionValid ||
      !courseValid ||
      !degreeValid ||
      !classofDegreeValid ||
      !startDate ||
      !endDate
    ) {
      toast.error("All fields are required");
      return;
    }
    else if(isOthers && !selectInstitutionId){
      toast.error('Error! please select institution')
    }
    else{
    let formData = {
      name: selectInstitution,
      degree: selectDegree,
      degree_id: degreeId,
      course: selectCourse,
      course_id: courseId,
      // location: selectState,
      class_of_degree: selectClassOfDegree,
      class_of_degree_id: classOfDegreeId,
      start_date: startDate,
      end_date: endDate,
      institution: selectInstitution,
      institution_id: selectInstitutionId,
    };

    try {
      toast.loading("loading...");
      const response = await api.createUserEducation(formData);
      toast.remove();
      customToast.success(response.message);
      sessionStorage.setItem("progress", "education");
      await refetchEducation();
      await refetchStatus();
      closeModal(false);
    } catch (error) {
      toast.remove();
      if (error.response) {
        customToast.error(error.response.data.message);
      } else {
        customToast.error(error.message);
      }
    }
   }
  };

  const addInstitution = () => {
     if(!otherInstitution) toast.error('Error! please add your institution name')
     else addInstitutionMutation.mutate({
       category: 'other',
       name: otherInstitution,
     })
  }

  const debouncedSearch = useDebounce((input) => {
    getInstitutionsMutation.mutate(input);
    setInstitutionSearch(input);
  }, 1500);

  const handleSearch = React.useCallback(
    (value) => {
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const courseDebouncedSearch = useDebounce((input) => {
    getCoursesMutation.mutate(input);
    setInstitutionSearch(input);
  }, 1500);

  const handleCourseSearch = React.useCallback(
    (value) => {
      courseDebouncedSearch(value);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    if (getInstitutionsMutation.isLoading) {
       setSearchInstitutionLoading(true)
    } else {
      setSearchInstitutionLoading(false)
    }
  }, [getInstitutionsMutation.isLoading]);

  useEffect(() => {
    if (getCoursesMutation.isLoading) {
       setSearchCourseLoading(true)
    } else {
      setSearchCourseLoading(false)
    }
  }, [getCoursesMutation.isLoading]);

  return (
    <div className="fixed mt-8 md:mt-0 top-0 left-0 w-screen h-screen grid place-items-center bg-black bg-opacity-50">
      <div className="">
        <div className="min-h-[11rem] md:min-h-[12rem] w-[340px] md:w-[600px] bg-white rounded-[32px] flex flex-col justify-center p-8 md:p-16 relative">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Add Education
          </h3>
          {isLoading ? (
            <div className="flex justify-center">
              <ScaleLoader color="#87AA4B" className="" />
            </div>
          ) : (
            <form onSubmit={createEducation}>
              <div className="mb-4 md:flex items-center relative">
                     {  searchInstitutionLoading &&                 
                      <div className="absolute text-xs z-[10000000000000] bg-white w-full md:w-[360px] py-3 text-center top-[70px]">
                         <div className="w-full h-full flex justify-center items-center">
                            <ScaleLoader color="#87AA4B" className="" height={10} width={10}/>
                         </div>
                      </div>
                      }
                      {
                        isOthers ? 
                        <div className="relative">
                              <label
                                htmlFor="gender"
                                className="text-gray-500 mb-4 text-base font-medium"
                              >
                                Add your Institution
                                <span className="text-red-500 required-dot">*</span>
                              </label>
                              <input 
                                 id="institution-input"
                                  placeholder="Add name of your institution"
                                  className={`rounded-lg  flex-1 appearance-none border ${
                                    "border-lightGrey"
                                  }  w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                                  type="text" value={otherInstitution}
                                  onChange={ e => setOtherInstitution(e.target.value) }
                               />
                         </div>
                        :
                        <div>
                         <label
                            htmlFor="gender"
                            className="text-gray-500 mb-4 text-base font-medium"
                          >
                            Name of Institution
                            <span className="text-red-500 required-dot">*</span>
                          </label>
                          <CustomDropdown 
                            width={'w-full md:w-[360px]'}
                            options={institutions}
                            value={
                                selectInstitutionId
                                  ? { value: selectInstitutionId, label: selectInstitution } // Provide both value and label
                                  : null
                              }
                              onChange={(selectedOption) => {
                                setSelectedInstitutionId(selectedOption?.value || "");
                                setSelectedInstitution(selectedOption?.label || "");
                                setInstitutionValid(!!selectedOption);
                              }}
                              onSearch={ (input) => {
                                setSearchInstitutionLoading(true)
                                handleSearch(input);
                              } }
                              placeholder='Select from default list or search your institution'
                          />
                          {!institutionValid && (
                            <p className="text-red-500 text-xs mt-1 text-right">
                              Please select an institution
                            </p>
                          )}
                     </div>
                      }
                     {/*<div className="flex items-center ml-8 pt-4">
                          <input 
                             type="checkbox"
                             checked={isOthers}
                             onClick={ () => {
                               setIsOthers(!isOthers)
                               setTimeout( () => {
                                 if(!isOthers) document.querySelector('#institution-input').focus()
                               }, 100)
                             } }
                             className="text-primary mr-2 bg-primary h-[20px] w-[20px]"
                             id="others"
                          />
                          <label htmlFor="other text-lg pl-2">Others</label>
                          {isOthers &&
                           <button
                            onClick={ addInstitution } type="button"
                            className="px-2 ml-5 bg-primary bi bi-plus-circle text-white text-sm py-2 rounded-lg">
                                  &nbsp;Add university
                          </button>
                          }
                        </div>*/}
              </div>

              <div className="mb-4 md:flex gap-x-4">
                <div className="mb-4 md:mb-0 w-full md:w-2/3 relative">
                {  searchCourseLoading &&                 
                      <div className="absolute text-xs z-[10000000000000] bg-white w-full py-3 text-center top-[70px]">
                         <div className="w-full h-full flex justify-center items-center">
                            <ScaleLoader color="#87AA4B" className="" height={10} width={10}/>
                         </div>
                      </div>
                      }
                  <label
                    htmlFor="gender"
                    className="text-gray-500 mb-4 text-base font-medium"
                  >
                    Courses
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  {/*<DatePicker selected={startDate_} onChange={(date) => setStartDate_(date)} />*/}
                  <CustomDropdown
                    width={"w-full"}
                    options={courses}
                    value={
                      courseId ? { value: courseId, label: selectCourse } : null
                    }
                    onChange={(selectedOption) => {
                      setCourseId(selectedOption?.value || "");
                      setSelectedCourse(selectedOption?.label || "");
                      setCourseValid(!!selectedOption);
                    }}
                    placeholder="Select Course"
                    onSearch={(input) => {
                      setSearchCourseLoading(true)
                      handleCourseSearch(input);
                    }}
                  />
                  {!courseValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a course
                    </p>
                  )}
                </div>
                <div className="mb-4 md:mb-0 w-full md:w-1/3">
                  <label
                    htmlFor="degree"
                    className="text-gray-500 mb-4 text-base font-medium"
                  >
                    Degree
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <CustomDropdown
                    width={"w-full md:w-[180px]"}
                    options={
                      degrees?.length
                        ? degrees?.map((degree) => {
                            return { label: degree?.name, value: degree?.id };
                          })
                        : []
                    }
                    value={
                      degreeId
                        ? { value: degreeId, label: selectDegree } // Provide both value and label
                        : null
                    }
                    onChange={(selectedOption) => {
                      setDegreeId(selectedOption?.value || "");
                      setSelectedDegree(selectedOption?.label || "");
                      setDegreeValid(!!selectedOption);
                    }}
                    placeholder="Select Degree"
                  />
                  {!degreeValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a degree
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-4 md:flex  gap-x-4">
                <div className="w-full  mb-4 md:mb-0">
                  <label
                    htmlFor="class_of_degree"
                    className="text-gray-500 mb-4 text-base font-medium"
                  >
                    Class Of Degree
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <CustomDropdown
                    width={"w-full"}
                    options={
                      classOfDegrees?.length
                        ? classOfDegrees?.map((degree) => {
                            return { label: degree?.name, value: degree?.id };
                          })
                        : []
                    }
                    value={
                      classOfDegreeId
                        ? { value: classOfDegreeId, label: selectClassOfDegree } // Provide both value and label
                        : null
                    }
                    onChange={(selectedOption) => {
                      setClassOfDegreeId(selectedOption?.value || "");
                      setSelectedClassOfDegree(selectedOption?.label || "");
                      setClassOfDegreeValid(!!selectedOption);
                    }}
                    placeholder="Select Class Of Degree"
                  />
                  {!classofDegreeValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a degree
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-8 md:flex gap-x-4">
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="start_date"
                    className="text-gray-500 mb-4 text-base font-medium"
                  >
                    Start Date
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <input
                    type="Date"
                    id="start_date"
                    className={`rounded-lg  flex-1 appearance-none border ${
                      !startDateValid ? "border-red-500" : "border-lightGrey"
                    }  w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                    name="start_date"
                    placeholder="Enter start date"
                    max={new Date().toISOString().split('T')[0]}
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setStartDateValid(true);
                    }}
                  />
                  {!startDateValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a degree
                    </p>
                  )}
                </div>
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="end_date"
                    className="text-gray-500 mb-4 text-base font-medium"
                  >
                    End Date
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <input
                    type="Date"
                    id="end_date"
                    className={`rounded-lg  flex-1 appearance-none border ${
                      !endDateValid ? "border-red-500" : "border-lightGrey"
                    }  w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                    name="end_date"
                    placeholder="Enter end date"
                    value={endDate}
                    min={startDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setEndDateValid(true);
                    }}
                  />
                  {!endDateValid && (
                    <p className="text-red-500 text-xs mt-1 text-right">
                      Please select a degree
                    </p>
                  )}
                </div>
              </div>

              <div className="flex gap-x-10 justify-center">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-primary  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-white text-sm md:text-lg font-medium text-primary  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-none  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-primary text-sm md:text-lg font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                >
                  Apply
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddEducation;
