import React from 'react'
 

const InputQuestions = ({question,testAssessment, setTestAssessment}) => {

  const throwUnsualWarning = (event) => {
     const form = document.querySelector(`form.form-${question.id}`)
     form.reset()     
  }

  const handleSavedAnswer = (event,answer) => {
          
    const savedAnswer = (answers, _updatedAnswer) => {
      
      const updatedAnswer = {
        answer_id : _updatedAnswer?.id ,
        question_id: _updatedAnswer?.question_id,
        answer_text: event.target.value,                         
        category_id: question?.category_id,
        section_id: question?.section_id,
        is_correct: _updatedAnswer?.is_correct
      }

      const index = answers.findIndex(obj =>  {
         
         return (obj.question_id === updatedAnswer.question_id) &&  (obj.answer_id === updatedAnswer.answer_id)
      });

      if (index >= 0) {
        const updatedAssessment = [...answers];
        updatedAssessment[index] = updatedAnswer;
        return updatedAssessment;
      } else {
        return [...answers, updatedAnswer];
      }
  
    }

    setTestAssessment(prevAssessment => {
      return {...prevAssessment, 
       savedAnswers : 
        [...savedAnswer(prevAssessment.savedAnswers, answer)]}
    })
  }

  const isSelectedAnswer = (answer) => {

    const updatedAnswer = {
      answer_id : answer?.id ,
      question_id: answer?.question_id,     
    }
  
    const index = testAssessment.savedAnswers.findIndex(obj =>  {                      
         return (obj.question_id === updatedAnswer.question_id) &&  (obj.answer_id === updatedAnswer.answer_id)     
   });
  
   if (index >= 0) {      
      return testAssessment.savedAnswers[index]?.answer_text
   }
   else return ''
  }

  return (
    <div>    
       <form className={`form-${question.id}`}>
       <div className='flex justify-between flex-wrap mt-5'>           
            <div className='md:flex justify-between flex-wrap'>
              <div className='md:flex items-center w-[100%] md:w-[50%] pr-2 mb-5'>
                   {
                    question.answers.map( answer => {
                      return(
                          <input 
                              type="text" name=""
                              defaultValue={isSelectedAnswer(answer)}
                              onCopy={ throwUnsualWarning }
                              onPaste={ throwUnsualWarning }
                              onCut={ throwUnsualWarning }                               
                              onBlur={ (event) => handleSavedAnswer(event,answer) }
                              className='w-[90%] md:w-[500px] text-lg mt-4 sm:mt-0 mr-5 py-3 px-3 border border-gray-200 rounded-lg focus:border-green-500 focus:outline-none'
                          /> 
                      )
                   })}                 
              </div>               
            </div>
        </div>
       </form>
    </div>
  )
}

export default InputQuestions