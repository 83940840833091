import React, { useEffect } from 'react'
import Spinner from '../../../components/Spinner'
import { useSearchParams, useNavigate, Link } from 'react-router-dom';

const Redirect = () => {

    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const name = searchParams.get('name');
    const token = searchParams.get('token');
    const id = searchParams.get('id');
    const navigate = useNavigate() 

  useEffect( () => {
   sessionStorage.setItem("userToken",token);
   sessionStorage.setItem("view", "showSideBar");
   sessionStorage.setItem('user', JSON.stringify({
     token,
     account_type: 'candidate',
     user: { user_id: id, name, email, }
   }))
   setTimeout( () => navigate('/candidate/assessments/view-assessments') , 500 )
  }, [] )

  return (
    <section className='mt-12'>
            <div>
                <Spinner text='Verifying...' />
            </div>
    </section>
  )
}

export default Redirect